import React from 'react';

import Button, {BUTTON_PRIMARY} from "../../atoms/Button";
import Arrow from "../../atoms/Arrow";

import './style.scss';

export const PLACEMENT_BEFORE = 'before';
export const PLACEMENT_AFTER = 'after';

function ArrowedButton({onClick = () => {}, disabled = false, buttonVariant = BUTTON_PRIMARY, variant = 'right', placement = PLACEMENT_AFTER, children = [], className = ''}) {
  const classes = `ArrowedButton ArrowedButton--${placement} ${className}`;

  return <Button className={classes} disabled={disabled} onClick={onClick} variant={buttonVariant}>
    { placement === PLACEMENT_BEFORE && <Arrow variant={variant}/> }
    { children }
    { placement === PLACEMENT_AFTER && <Arrow variant={variant}/> }
  </Button>
}

export default ArrowedButton;
