import React, {Component} from 'react';
import {debounce, get} from 'lodash';
import {getMatomoQueue} from "../globals";

const _matomo = {
  initialized: false,
};

window.matomoAsyncInit = () => {
  _matomo.initialized = true;
}

const whenMatomoReady = (...callbacks) => _matomo.initialized && callbacks.forEach(callback => callback());

export const trackPage = debounce((url) => {
  whenMatomoReady(
    () => getMatomoQueue().push(['trackPageView']),
  );
}, 500);

export const trackEvent = (options) => {
  whenMatomoReady(
    () => getMatomoQueue().push(['trackEvent', options.category, options.action, options.label]),
  );
};
/**
 *
 * @param {MouseEvent<HTMLAnchorElement>} e
 */
export const trackLink = (e) => {
  /** @type {HTMLAnchorElement} */
  const anchor = e.currentTarget;

  whenMatomoReady(
    () => getMatomoQueue().push(['trackLink', anchor.href, anchor.download ? 'download' : 'link']),
  );
};

export const actions = {
  DOWNLOAD: 'download',
  EXTERNAL: 'external',
  CLICKED: 'clicked',
};

export default function withTracker(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.onCookiebotConsentReady = this.onCookiebotConsentReady.bind(this);
      this.state = {
        isTrackable: !!get(window, 'Cookiebot.consent.statistics'),
        isMatomoInitialized: false,
      }
    }

    componentDidMount() {
      window.addEventListener('CookiebotOnConsentReady', this.onCookiebotConsentReady, false);
      if (this.state.isTrackable) {
        trackPage(this.props.location.pathname);
      }
    }

    componentDidUpdate() {
      if (this.state.isTrackable) {
        trackPage(this.props.location.pathname);
      }
    }

    componentWillUnmount() {
      window.removeEventListener('CookiebotOnConsentReady', this.onCookiebotConsentReady, false);
    }

    onCookiebotConsentReady() {
      const isTrackable = !!get(window, 'Cookiebot.consent.statistics');

      if (isTrackable) {
        getMatomoQueue().push(['setCookieConsentGiven']);
        getMatomoQueue().push(['setConsentGiven']);
      } else {
        getMatomoQueue().push(['forgetCookieConsentGiven']);
        getMatomoQueue().push(['forgetConsentGiven']);
      }

      this.setState({isTrackable});
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
