import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {injectIntl} from "react-intl";

import OffersSlider, {MAX_OFFERS_TO_FETCH} from "../organisms/OffersSlider";
import Banners from "../organisms/Banners";
import Loader from "../atoms/Loader";
import {createSetTitleCallback} from "../../utils/metadata";
import {withStores} from "../../stores";
import withTracker from "../../utils/withTracker";

import './Home/Home.scss';

const HomeTopics = withStores(
  observer(({topicsStore}) => {
    if (topicsStore.isLoading) {
      return <Loader/>
    }

    return topicsStore.ordered.map(
      topic => <div className="row" key={topic.id}>
        <div className="col">
          <OffersSlider topic={topic} limit={MAX_OFFERS_TO_FETCH} key={topic.id}/>
        </div>
      </div>
    );
  })
);

const Home = ({intl, criteriaStore}) => {
  useEffect(createSetTitleCallback({
    title: intl.formatMessage({id: 'page.home.title'})
  }, 'title'));

  criteriaStore.clear();

  return <div className="Home">
    <Banners/>
    <div className="container">
      <HomeTopics/>
    </div>
  </div>;
};

export default injectIntl(
  withStores(
    withTracker(
      observer(Home),
    ),
  ),
);
