import React from 'react';
import {observer} from "mobx-react";
import {pick} from "lodash";

import './style.scss';
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";

class SearchInput extends React.Component {
  static allowedProperties = [
    'disabled',
    'readonly',
    'value',
    'type',
    'className',
    'onClick',
    'onDoubleClick',
    'onTouchEnd',
    'onChange',
    'onKeyDown',
    'placeholder',
  ];

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: props.value || '',
    };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.setState({
        value: '',
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getProperties() {
    const props = Object.assign(
      {},
      this.props,
      {
        value: this.state.value,
        type: this.props.type || 'text',
        onChange: e => {
          this.props.onChange(e.target.value);
          this.setState({value: e.target.value});
        },
        className: `SearchInput ${this.props.className}`,
        placeholder: this.props.placeholder || this.props.intl.formatMessage({
          id: 'molecules.search_input.placeholder',
          defaultMessage: 'Search...'
        }),
        autoFocus: true,
      }
    );

    return pick(props, SearchInput.allowedProperties);
  }

  render() {
    return <input {...this.getProperties()}/>
  }
}

export default withRouter(
  injectIntl(
    observer(SearchInput),
  ),
);
