import {grey} from '@material-ui/core/colors';
import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";

import colors from '../../../assets/variables.scss';

const MuiInputLabel = {
  root: {
    '&$marginDense': {
      color: `${colors['color-form-text']} !important`,
      transform: 'translate(22px, 32px) scale(1)',
    },
    '&$formControl': {
      color: `${colors['color-form-text']} !important`,
      transform: 'translate(22px, 32px) scale(1)',
    },
    '&$shrink': {
      transform: 'translate(20px, -2px) scale(0.85)',
      color: `${colors['color-form-text']} !important`,
    },
  },
};

const MuiFormControl = {
  root: {
    width: '100%',
    marginTop: 7,
    marginBottom: 4,
    '&$focused': {
      border: 0,
    }
  },
  marginDense: {
    marginTop: 7,
  },
};

const MuiSelect = Object.assign({}, MuiInputLabel, {
  select: {
    paddingTop: 3,
    paddingBottom: 3,
    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
});

const MuiFormLabel = {
  root: {
    zIndex: 1,
    fontSize: '.875rem',
  },
};

const MuiInputBase = {
  root: {
    padding: '10px 0 !important',
    borderRadius: 4,
    backgroundColor: colors['color-iron'],
    color: colors['color-form-text'],
    fontSize: '.875rem',
    '&.Mui-error': {
      border: `2px solid ${colors['color-secondary']}`,
    },
  },
  input: {
    paddingLeft: '20px !important',
    '&$inputMarginDense': {
      paddingTop: 3,
      paddingBottom: 3,
    },
  },
};

const theme = responsiveFontSizes(
  createMuiTheme({
    spacing: 2,
    palette: {
      primary: grey,
    },
    overrides: {
      MuiFormLabel,
      MuiInputBase,
      MuiFormControl,
      MuiInputLabel,
      MuiSelect,
    },
  }),
);

export default theme;
