import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {createSetTitleCallback} from "../../utils/metadata";

function NotFound({intl}) {
  useEffect(createSetTitleCallback({
    title: intl.formatMessage({id: 'page.not_found.title'})
  }, 'title'));

  return (<div className="container">
    <div className="row">
      <div className="col d-flex flex-column align-items-center justify-content-center">
        <div>
          <h1>
            <FormattedMessage
              id="pages.not_found.title"
              defaultMessage="Not found"
            />
          </h1>
        </div>
        <div>
          <p>
            <FormattedMessage
              id="pages.not_found.description"
              defaultMessage="The page you're looking for does not exist."
            />
          </p>
        </div>
      </div>
    </div>
  </div>);
}

export default injectIntl(NotFound);
