import $ from 'jquery';

window.$ = $;
window.jQuery = $;

export const GOOGLE_TAG_ID = process.env.REACT_APP_GA || null;
export const COOKIEBOT_ID = process.env.REACT_APP_COOKIEBOT_ID || null;
export const MATOMO_SITE_ID = process.env.REACT_APP_MATOMO_ID || null

const isMatomoDebuggable = () => window && window.localStorage && window.localStorage.getItem('matomo/debug') === 'true';

export const getMatomoQueue = () => {
  return window._paq = window._paq || [];
}

const initializeMatomo = () => {
  if (!MATOMO_SITE_ID) return;
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  const u = "https://matomo.ovos.at/";
  getMatomoQueue().push(['setTrackerUrl', u + 'matomo.php']);
  getMatomoQueue().push(['setSiteId', MATOMO_SITE_ID]);
  const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
  g.async = true;
  g.src = u + 'matomo.js';
  s.parentNode.insertBefore(g, s);

  if (isMatomoDebuggable()) {
    getMatomoQueue().push(['logAllContentBlocksOnPage']);
  }
};

window.addEventListener('CookiebotOnConsentReady', initializeMatomo, false);
