import {gql} from "apollo-boost";

export const GetTopics = gql`
  query GetTopics ($name: String) {
    taxonomyTermQuery(
      offset: 0,
      limit: 500,
      filter: {
        conditions: [
          {operator: EQUAL, field: "vid", value: ["category"]},
          {operator: LIKE, field: "name", value: [$name]},
          {operator: EQUAL, field: "status", value: ["1"] }
        ]
      }
      sort: { field: "name", direction: ASC }
    ) {
      count
      entities {
        ... on TaxonomyTerm {
          entityLabel
          entityId
          weight
          tags: reverseFieldTagsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          years: reverseFieldYearsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          topics: reverseFieldCategoriesNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
        }
      }
    }
  }
`;

export const GetYears = gql`
  query GetYears ($name: String) {
    taxonomyTermQuery(
      offset: 0,
      limit: 500,
      filter: {
        conditions: [
          {operator: EQUAL, field: "vid", value: ["years"]},
          {operator: LIKE, field: "name", value: [$name]},
          {operator: EQUAL, field: "status", value: ["1"] }
        ]
      }
      sort: { field: "name", direction: ASC }
    ) {
      count
      entities {
        ... on TaxonomyTerm {
          entityLabel
          entityId
          weight
          tags: reverseFieldTagsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          years: reverseFieldYearsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          topics: reverseFieldCategoriesNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
        }
      }
    }
  }
`;

export const GetTags = gql`
  query GetTags ($name: String, $limit: Int!, $offset: Int!) {
    taxonomyTermQuery(
      offset: $offset,
      limit: $limit,
      filter: {
        conditions: [
          {operator: EQUAL, field: "vid", value: "tags"},
          {operator: LIKE, field: "name", value: [$name]},
          {operator: EQUAL, field: "status", value: ["1"] }
        ]
      }
      sort: { field: "name", direction: ASC }
    ) {
      count
      entities {
        ... on TaxonomyTerm {
          entityLabel
          entityId
          weight
          tags: reverseFieldTagsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          years: reverseFieldYearsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          topics: reverseFieldCategoriesNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
        }
      }
    }
  }
`;

export const GetTagsAndYears = gql`
  query GetTags ($name: String, $limit: Int!, $offset: Int!) {
    taxonomyTermQuery(
      offset: $offset,
      limit: $limit,
      filter: {
        conjunction:AND
        groups: [
          {
            conjunction: OR
            conditions: [
              {operator: EQUAL, field: "vid", value: ["tags"]},
              {operator: EQUAL, field: "vid", value: ["years"]},
              {operator: EQUAL, field: "status", value: ["1"] },
            ]
          },
          {

            conditions: [
              {operator: LIKE, field: "name", value: [$name]},
              {operator: EQUAL, field: "status", value: ["1"] }
            ]
          }
        ]
      },
      sort: { field: "name", direction: ASC }
    ) {
      count
      entities {
        ... on TaxonomyTerm {
          name
          entityLabel
          entityId
          vid {
            targetId
          }
          tags: reverseFieldTagsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          years: reverseFieldYearsNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
          topics: reverseFieldCategoriesNode (filter: {
              conditions: [
                  {operator: EQUAL, field: "status", value: ["1"]}
              ]
          }) {
            count
          }
        }
      }
    }
  }
`;

export const GetOffers = gql`
  query GetOffers($filter: EntityQueryFilterInput!, $limit: Int!, $offset: Int!) {
    nodeQuery (
      limit: $limit
      offset: $offset
      sort:  [{ field: "field_years.entity.name", direction: DESC}, { field: "created", direction: DESC}]
      filter: $filter
    ) {
      count
      entities {
        ... on NodePage {
          entityId
          title
          created
          changed
          body {
            value
            format
            processed
            summary
            summaryProcessed
          }
          isNew: fieldNew
          entityUrl {
            path
            routed
          }
          fieldThumbnail {
              entity {
                  ... on MediaImage {
                      name
                      thumbnail {
                          alt
                          title
                          url
                      }
                      fieldMediaImage {
                          alt
                          fullhd: derivative(style: FULLHD) {
                              url
                              width
                              height
                          }
                          thumbnail: derivative(style: LARGE) {
                              url
                              width
                              height
                          }
                      }
                  }
              }
          }
          fieldTags: queryFieldTags(
            filter: {
              conditions: [
                {operator: EQUAL, field: "status", value: ["1"]}
              ]
            }
          ) {
            entities {
              ... on TaxonomyTermTags {
                name
                tid
              }
            }
          }
          fieldYears: queryFieldYears (
            filter: {
              conditions: [
                {operator: EQUAL, field: "status", value: ["1"]}
              ]
            }
          ) {
            entities {
              ... on TaxonomyTermYears {
                name
                tid
              }
            }
          }
          fieldCategories: queryFieldCategories (
            filter: {
              conditions: [
                {operator: EQUAL, field: "status", value: ["1"]}
              ]
            }
          ) {
            entities {
              ... on TaxonomyTermCategory {
                name
                tid
              }
            }
          }
          buttons: fieldButtons {
            entity {
              ... on MediaAttachment {
                name
                type: entityBundle
                file: fieldMediaFile {
                  entity {
                    id: entityId
                    filename
                    url
                  }
                }
              }
              ... on MediaExternalLink {
                name
                type: entityBundle
                link: fieldMediaExternalLink {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GetOffersLight = gql`
  query GetOffers($filter: EntityQueryFilterInput!, $limit: Int!, $offset: Int!) {
    nodeQuery (
      limit: $limit
      offset: $offset
      sort: { field: "created", direction: DESC}
      filter: $filter
    ) {
      count
      entities {
        ... on NodePage {
          entityId
          title
        }
      }
    }
  }
`;


export const GetOffer = gql`
  query GetOffer($id: String!) {
    nodeQuery (
      limit: 1
      offset: 0
      sort: { field: "created", direction: DESC}
      filter: {
        conditions: [
          { field: "nid", value: [$id], operator: EQUAL}
        ]
      }
    ) {
      count
      entities {
        ... on NodePage {
          id: nid
          title
          created
          changed
          body {
            value
            format
            processed
            summary
            summaryProcessed
          }
          entityUrl {
            path
            routed
          }
          fieldThumbnail {
            entity {
              ... on MediaImage {
                name
                thumbnail {
                  alt
                  title
                  url
                }
                fieldMediaImage {
                  alt
                  fullhd: derivative(style: FULLHD) {
                    url
                    width
                    height
                  }
                  thumbnail: derivative(style: LARGE) {
                    url
                    width
                    height
                  }
                }
              }
            }
          }
          fieldTags: queryFieldTags(
            filter: {
              conditions: [
                {operator: EQUAL, field: "status", value: ["1"]}
              ]
            }
          ) {
            entities {
              ... on TaxonomyTermTags {
                name
                tid
              }
            }
          }
          fieldYears: queryFieldYears (
            filter: {
              conditions: [
                {operator: EQUAL, field: "status", value: ["1"]}
              ]
            }
          ) {
            entities {
              ... on TaxonomyTermYears {
                name
                tid
              }
            }
          }
          fieldCategories: queryFieldCategories (
            filter: {
              conditions: [
                {operator: EQUAL, field: "status", value: ["1"]}
              ]
            }
          ) {
            entities {
              ... on TaxonomyTermCategory {
                name
                tid
              }
            }
          }
          isNew: fieldNew
          isOrderable: fieldOrderable
          buttons: fieldButtons {
            entity {
              ... on MediaAttachment {
                id: mid
                name
                type: entityBundle
                file: fieldMediaFile {
                  entity {
                    id: fid
                    filename
                    url
                  }
                }
              }
              ... on MediaExternalLink {
                name
                type: entityBundle
                link: fieldMediaExternalLink {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const GetStaticPage = gql`
  query GetStaticPage($path: String!) {
    route(path: $path) {
      ... on EntityCanonicalUrl {
        entity {
          ... on NodeStaticPage{
            nid
            title
            path {
              alias
            }
            body {
              value
            }
          }
        }
      }
    }
  }
`;

export const SubmitOrder = gql`
    mutation SubmitOrder ($submitOrder: SubmitOrderInput!) {
        submitOrder(input: $submitOrder, language: DE) {
            violations {
                path
                code
                message
            }
            errors
            entity {
                ... on NodeOrder {
                    fieldZip
                    fieldCity
                    fieldEmail
                    fieldFirstName
                    fieldLastName
                    fieldStreet
                    fieldTitle
                    fieldSchool
                    fieldQuantity
                    fieldOffer {
                        targetId
                    }
                }
            }
        }
    }
`;

export const GetTermsByGroups = gql`
    query GetTermsByGroups ($taxonomy: [String], $groups: [String]) {
        taxonomyTermQuery(
            offset: 0,
            limit: 10,
            filter: {
                conditions: [
                    {operator: EQUAL, field: "vid", value: $taxonomy},
                    {operator: EQUAL, field: "status", value: ["1"]},
                    {operator: IN, field: "field_groups", value: $groups}
                ]
            }
            sort: { field: "name", direction: ASC }
        ) {
            count
            entities {
                ... on TaxonomyTerm {
                    entityLabel
                    entityId
                    weight
                }
            }
        }
    }
`;

export const GetBanners = gql`
query GetBanners($filter: EntityQueryFilterInput!, $limit: Int!, $offset: Int!) {
  nodeQuery(limit: $limit, offset: $offset, sort: [{field: "field_years.entity.name", direction: DESC}, {field: "created", direction: DESC}], filter: $filter) {
    count
    entities {
      ... on NodeBanner {
        entityId
        title
        created
        changed
        titleHidden: fieldHidden
        body {
          value
          format
          processed
          summary
          summaryProcessed
        }
        fieldLink {
          uri
          title
          options
        }
        fieldImage {
          entity {
            ... on MediaImage {
              name
              thumbnail {
                alt
                title
                url
              }
              fieldMediaImage {
                alt
                fullhd: derivative(style: FULLHD) {
                  url
                  width
                  height
                }
                thumbnail: derivative(style: LARGE) {
                  url
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export default {
  GetTopics,
  GetTags,
  GetOffers,
  GetOffer,
  GetStaticPage,
  GetTagsAndYears,
  GetSchoolTags: GetTermsByGroups,
  GetOffersLight,
  GetBanners,
  SubmitOrder,
};
