import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, useMediaQuery, useTheme} from "@material-ui/core";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Subscriptions from "../../organisms/Subscriptions";
import {FormattedMessage} from "react-intl";

function SubscriptionPopup({ children }) {
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function handleClose() {
    setOpen(false);
  }

  function handleOpen(e) {
    if (e instanceof Event) e.preventDefault();
    setOpen(true);
  }

  const clickableChild = React.cloneElement(children, {
    onClick: handleOpen,
  });

  return (
    <>
      <Dialog open={isOpen} fullScreen={fullScreen} onClose={handleClose} maxWidth="md" fullWidth={true} aria-labelledby="form-dialog-title" className="Footer-subscriptions">
        <DialogActions>
          <Button onClick={handleClose}><Icon icon="close"/></Button>
        </DialogActions>
        <DialogContent>
          <Subscriptions/>
        </DialogContent>
        {fullScreen && (
          <Button onClick={handleClose}>
            <FormattedMessage
              id="organisms.offer_details.close"
              defaultMessage="Close"
            />
          </Button>
        )}
      </Dialog>
      {clickableChild}
    </>
  )
};

export default SubscriptionPopup;
