import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage, injectIntl} from "react-intl";

import {withStores} from "../../../stores";
import FooterMenu from "../../molecules/FooterMenu";
import Copyright from "../../molecules/Copyright";
import Partners from "../../molecules/Partners";
import Loader from "../../atoms/Loader";
import {FACEBOOK, INSTAGRAM, SecondaryMenu, YOUTUBE} from "../../../utils/staticPages";
import FooterSocialBox from "../../molecules/FooterSocialBox";
import SubscriptionPopup from "../../molecules/SubscriptionPopup";

import './style.scss';

const mapMenuToLinks = (menu, intl) => {
  return Object.values(menu).map((element) => ({
    label: intl.formatMessage({
      id: element.id,
      defaultMessage: element.defaultMessage,
    }),
    url: element.url,
    external: !!element.external,
  }))
};

const FooterTopics = injectIntl(withStores(observer(
  function ({topicsStore, intl}) {
    if (topicsStore.isLoading) {
      return <Loader/>
    }

    return <FooterMenu items={mapMenuToLinks(SecondaryMenu, intl)}/>
  }
)));

function FooterSocialLinks() {
  return <div className="">
    <div className="container">
      <div className="row Footer-row--blue">
        <p className="col">
          <FormattedMessage
            id="organisms.footer.social_header"
            default="Informieren Sie sich regelmäßig über unsere aktuellen Angebote:"
          />
        </p>
      </div>
      <div className="row Footer-row--blue">
        <div className="col-sm-12 col-md-3">
          <SubscriptionPopup>
            <FooterSocialBox icon="mail" className="FooterSocialBox--splitted FooterSocialBox--first">
              <FormattedMessage
                id="molecules.social_media.newsletter"
                defaultMessage="Newsletter"
              />
            </FooterSocialBox>
          </SubscriptionPopup>
        </div>
        <div className="col-sm-12 col-md-3">
          <FooterSocialBox icon="facebook" url={FACEBOOK} className="FooterSocialBox--splitted">
            <FormattedMessage
              id="molecules.social_media.facebook"
              defaultMessage="Facebook"
            />
          </FooterSocialBox>
        </div>
        <div className="col-sm-12 col-md-3">
          <FooterSocialBox icon="instagram" url={INSTAGRAM} className="FooterSocialBox--splitted">
            <FormattedMessage
              id="molecules.social_media.instagram"
              defaultMessage="Instagram"
            />
          </FooterSocialBox>
        </div>
        <div className="col-sm-12 col-md-3">
          <FooterSocialBox icon="youtube" url={YOUTUBE}>
            <FormattedMessage
              id="molecules.social_media.youtube"
              defaultMessage="Youtube"
            />
          </FooterSocialBox>
        </div>
      </div>
    </div>
  </div>;
}

function FooterSocialContainer() {
  return <div className="Footer-social">
    <div className="Footer-main">
      <FooterSocialLinks/>
    </div>
  </div>;
}

function FooterMenuBar() {
  return <div className="Footer-menu">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-6 d-flex align-items-center">
          <FooterTopics/>
        </div>
        <div className="col-sm-12 col-md-6 d-flex justify-content-lg-end justify-content-md-start">
          <Partners/>
        </div>
      </div>
    </div>
  </div>;
}

function FooterCopyright() {
  return <div className="Footer-copyright">
    <div className="container">
      <div className="row">
        <div className="col">
          <Copyright/>
        </div>
      </div>
    </div>
  </div>;
}


function Footer() {
  return <footer className="Footer">
    <FooterSocialContainer/>
    <FooterMenuBar/>
    <FooterCopyright/>
  </footer>;
}

export default withStores(
  observer(Footer),
);
