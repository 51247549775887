import React from 'react';
import {get} from 'lodash';

import {PRIVACY} from "../../../utils/staticPages";
import {COOKIEBOT_ID} from "../../../globals";

export default function CookiebotInfobox({ page }) {
  if (get(page, 'path.alias') !== PRIVACY) {
    return null;
  }

  if (!COOKIEBOT_ID) {
    return null;
  }

  return <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-6 offset-md-3">
        <script id="CookieDeclaration" src={`https://consent.cookiebot.com/${COOKIEBOT_ID}/cd.js`} type="text/javascript" async/>
      </div>
    </div>
  </div>
}
