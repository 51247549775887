import React, {Component} from 'react';
import {ApolloProvider} from 'react-apollo';
import {IntlProvider} from 'react-intl';

import {drupalClient} from './api';
import {StoreContext, stores} from './stores'
import AppRouter from './AppRouter';

import de from './translations/de.json';

const LANG_DE = 'de';

class App extends Component {
  render() {
    return (
      <IntlProvider locale={LANG_DE} messages={de}>
        <StoreContext.Provider value={stores}>
          <ApolloProvider client={drupalClient}>
            <AppRouter/>
          </ApolloProvider>
        </StoreContext.Provider>
      </IntlProvider>
    );
  }
}

export default App;
