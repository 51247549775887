import React, {useState} from 'react';
import {observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {Dialog, DialogContent, useMediaQuery, useTheme} from "@material-ui/core";
import {get} from "lodash";
import {Redirect} from "react-router-dom";

import {withStores} from "../../../stores";
import Loader from "../../atoms/Loader";
import OfferTags from "../../molecules/OfferTags";
import Image from "../../atoms/Image";
import OfferButtons, {TYPE_ATTACHEMENT, TYPE_EXTERNAL} from "../../molecules/OfferButtons";
import {Mail, Twitter, Facebook} from "../../molecules/SocialSharing";
import Divider from "../../atoms/Divider";
import OfferSuggestions from "../../molecules/OfferSuggestions";
import Triangles from "../../molecules/Triangles";
import NewIcon from "../../atoms/NewIcon";
import {createSetTitleCallback} from "../../../utils/metadata";
import OrderForm from "../OrderForm";
import Button, {BUTTON_SECONDARY} from "../../atoms/Button";

import './style.scss';
import {sanitizeHtml} from "../../../utils/strings";

function SidebarSection({title, body, inline = false}) {
  return <section className={`OfferDetails-section ${inline ? 'OfferDetails-section--inline' : ''}`}>
    <h3 className="OfferDetails-section-title">
      {title}:
    </h3>
    <div className="OfferDetails-section-body">
      {body}
    </div>
  </section>
}

const OrderFormDialog = ({offer: {title, id}}) => {
  const [isOpen, setOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  function handleClose() {
    setOpen(false);
    setIsSent(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function onSubmitCallback() {
    handleClose();
    setIsSent(true);
  }

  return <>
    <Button onClick={handleOpen} variant="secondary">
      <FormattedMessage
        id="organisms.offer_details.open_form"
        defaultMessage="Printausgabe bestellen"
      />
    </Button>
    <Dialog open={isSent} fullScreen={fullScreen} onClose={handleClose} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogContent>
        <h3>
          <FormattedMessage
            id="organisms.offer_details.order_thank_you"
            defaultMessage="Thank you!"
          />
        </h3>
        <p>
          <FormattedMessage
            id="organisms.offer_details.order_sent"
            defaultMessage="Your order has been sent"
          />
        </p>
        <div>
          <Button onClick={handleClose} variant={BUTTON_SECONDARY}>
            <FormattedMessage
              id="organisms.offer_details.close"
              defaultMessage="Close"
            />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
    <Dialog open={isOpen} fullScreen={fullScreen} onClose={handleClose} maxWidth="md" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogContent>
        <OrderForm title={title} offerId={id} onSubmitCallback={onSubmitCallback}/>
      </DialogContent>
      {fullScreen && (
        <Button onClick={handleClose}>
          <FormattedMessage
            id="organisms.offer_details.close"
            defaultMessage="Close"
          />
        </Button>
      )}
    </Dialog>
  </>
};

class OfferDetails extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {isLoading: true, id: null};
    this.addingPageFilter = this.addingPageFilter.bind(this);
    this.updateHtmlMetadata = createSetTitleCallback(this, 'props.offer.title');
  }

  componentDidMount() {
    this.addingPageFilter();
    this.updateHtmlMetadata();
    this.setState({isLoading: false});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.addingPageFilter();
    this.updateHtmlMetadata();
    if (this.state.id !== prevState.id) {
      this.timeout = setTimeout(() => this.setState({isLoading: false}), 500);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.id) {
      return {
        isLoading: true,
        id: props.id,
      };
    }

    return null;
  }

  addingPageFilter() {
    /**
     * @type {CriteriaStore} criteriaStore
     */
    const {offer, criteriaStore, pagesStore} = this.props;

    if (!offer || !criteriaStore) {
      return;
    }

    pagesStore.add(offer);
    criteriaStore.addPages(offer.id);
  }

  render() {
    const {offer, intl, loading} = this.props;
    const thumbnailUrl = get(offer, 'fieldThumbnail.entity.fieldMediaImage.fullhd.url', null);
    const thumbnailAlt = get(offer, 'fieldThumbnail.entity.fieldMediaImage.alt', null);

    if (!offer) {
      return <Redirect to="/404"/>
    }

    if (loading || this.state.isLoading) {
      return (<Loader/>);
    }

    let offerTags = get(offer, 'fieldTags.entities', []);
    return <article className={'col OfferDetails'} ref={this.elementRef}>
      <div className="row">
        <div className="col-sm-12 col-md-4 order-12 order-md-1 OfferDetails-sidebar">
          <div className="OfferDetails-thumbnail d-none d-md-block d-lg-block d-xl-block">
            { !!thumbnailUrl && !!thumbnailAlt && (
              <Image src={thumbnailUrl} alt={thumbnailAlt}/>
            )}
            {offer.isNew && (<div className="OfferCard-new">
              <NewIcon label={intl.formatMessage({
                id: 'organisms.offer_card.new',
                defaultMessage: 'New'
              })}/>
            </div>)}
          </div>
          <SidebarSection
            inline={true}
            title={<FormattedMessage
              id="organisms.offer_details.share"
              defaultMessage="Share"
            />}
            body={<div className="OfferDetails-sharing">
              <Facebook/>
              <Twitter/>
              <Mail/>
            </div>}
          />
        </div>
        <div className="col-sm-12 col-md-8 order-1 order-md-12 OfferDetails-content">
          <div className="OfferDetails-thumbnail d-md-none">
            <Image src={offer.fieldThumbnail.entity.thumbnail.url}
                   alt={offer.fieldThumbnail.entity.thumbnail.alt}
            />
            {offer.isNew && (<div className="OfferCard-new">
              <NewIcon label={intl.formatMessage({
                id: 'organisms.offer_card.new',
                defaultMessage: 'New'
              })}/>
            </div>)}
          </div>
          <h1 className="OfferDetails-title" dangerouslySetInnerHTML={{__html: sanitizeHtml(offer.title)}}/>
          <div className="OfferDetails-tags">
            <OfferTags
              years={get(offer, 'fieldYears.entities', [])}
              categories={get(offer, 'fieldCategories.entities', [])}
            />
          </div>
          <div dangerouslySetInnerHTML={{__html: offer.body.value}} className="OfferDetails-body"/>
          <div className="OfferDetails-buttons  OfferDetails-buttons--left">
            <OfferButtons offer={offer} type={TYPE_ATTACHEMENT}>
              { !!offer.isOrderable && (
                <OrderFormDialog offer={offer}/>
              ) }
            </OfferButtons>
          </div>

          <div className="OfferDetails-buttons OfferDetails-buttons--left">
            <OfferButtons offer={offer} type={TYPE_EXTERNAL}/>
          </div>
          <Divider/>
          <SidebarSection
            title={<FormattedMessage
              id="organisms.offer_details.might_be_interesting"
              defaultMessage="That might interest you, too"
            />}
            body={<OfferSuggestions offer={offer} className="OfferDetails-suggestions"/>}
          />
          { !!offerTags.length && <SidebarSection
            title={<FormattedMessage
              id="organisms.offer_details.tags"
              defaultMessage="Tags"
            />}
            body={<OfferTags tags={offerTags}/>}
          /> }
          <Triangles className="col-sm-12 col-md-12"/>
        </div>
      </div>
    </article>
  }
}

export default withStores(
  injectIntl(
    observer(OfferDetails),
  ),
);
