import React from 'react';
import {isFunction} from 'lodash';

import Icon from "../../atoms/Icon";
import Button from "../../atoms/Button";

import './style.scss';

function FooterSocialBox({icon, url, onClick = null, children = [], className = ''}) {
  const isButtonable = isFunction(onClick);
  return <div className={`FooterSocialBox ${className}`}>
    <div className="FooterSocialBox-icon">
      <Icon icon={icon}/>
    </div>
    { !isButtonable && (
      <a href={url} rel="noopener noreferrer" target="_blank" className="Button Button--secondary Button--square">
        {children}
      </a>
    ) }
    { isButtonable && (
      <Button onClick={onClick} className="Button Button--secondary Button--square">
        {children}
      </Button>
    ) }
  </div>;
}

export default FooterSocialBox;
