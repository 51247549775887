import React from 'react';
import {withRouter} from 'react-router-dom';
import {observer} from "mobx-react";
import {graphql} from "react-apollo";
import {FormattedMessage} from "react-intl";

import {withStores} from "../../../stores";
import {GetOffers, getOffersConditions, mapNodeEntities} from "../../../api";
import {chunk} from "../../../utils";
import {ARROW_DOWN} from "../../atoms/Arrow";
import Transition from '../../molecules/Transition';
import Loader from "../../atoms/Loader";
import OfferCard from "../OfferCard";
import ArrowedButton, {PLACEMENT_AFTER} from "../../molecules/ArrowedButton";

import './style.scss';
import LoadingDelay from "../../atoms/LoadingDelay";

const ITEMS_PER_PAGE = 8;
const ITEMS_PER_ROW = 4;
const LOADING_DELAY_MILISECONDS = 600;

const fetchMoreHandler = ({criteriaStore}) => ({
  variables: {
    offset: criteriaStore.page * ITEMS_PER_PAGE,
  },
  updateQuery: (previousResult, {fetchMoreResult}) => {
    return {
      ...previousResult,
      nodeQuery: {
        ...previousResult.nodeQuery,
        entities: [
          ...previousResult.nodeQuery.entities,
          ...fetchMoreResult.nodeQuery.entities,
        ]
      }
    };
  }
});

const LoadMoreButton = ({shown, count, onClick, disabled}) => {
  if (shown >= count) {
    return null;
  }

  return (
    <ArrowedButton onClick={onClick} disabled={disabled} className="SearchResults-more" placement={PLACEMENT_AFTER} variant={ARROW_DOWN}>
      <FormattedMessage
        id="organisms.search_results.load_more"
        defaultMessage="Load more"
        description="Search Results - Load More button"
      />
    </ArrowedButton>
  );
};

const withOffersQuery = graphql(GetOffers, {
  /**
   * @param {CriteriaStore} criteriaStore
   * @returns {{notifyOnNetworkStatusChange: boolean, variables: {offset: number, limit: number, conditions: Object[]}, fetchPolicy: string}}
   */
  options: ({criteriaStore}) => {
    return {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: getOffersConditions(
          {
            topics: [...criteriaStore.topics],
            tags: [...criteriaStore.tags],
            years: [...criteriaStore.years],
          }
        ),
        limit: ITEMS_PER_PAGE * criteriaStore.page + ITEMS_PER_PAGE,
        offset: 0,
      },
    };
  },
  props: ({data: {error, loading, nodeQuery, fetchMore}}) => ({
    loading,
    error,
    fetchMore,
    offers: mapNodeEntities({nodeQuery}, item => item),
    count: nodeQuery ? nodeQuery.count : 0,
    limit: ITEMS_PER_PAGE,
  }),
});

export const SearchResultsRow = ({offers = []}) => {
  const columns = [];

  offers.forEach((offer, key) => {
    columns.push(
      <div className={"SearchResults-column"}  key={key}>
        <OfferCard offer={offer}/>
      </div>
    );
  });

  return <div className="row SearchResults-row">{columns}</div>
};

const SearchResults = ({criteriaStore, loading, error, fetchMore, limit, count, offers = []}) => (
  <div className="container SearchResults">
    <LoadingDelay check={loading} delay={LOADING_DELAY_MILISECONDS}>
      {({isLoading, isDelaying}) => {
        if (isLoading || isDelaying) {
          return <Loader/>;
        }

        return <>
          <Transition in={!!offers.length && !loading} unmountOnExit>
            <div>
              {chunk(offers, ITEMS_PER_ROW).map((items, key) => {
                return <SearchResultsRow offers={items} key={key}/>
              })}
            </div>
          </Transition>
          <Transition in={offers.length === 0}  unmountOnExit>
            <div className={"col"}>
              <h1>
                <FormattedMessage
                  id="organisms.search_results.no_results"
                  defaultMessage="No results"
                  description="Search Results - No results"
                />
              </h1>
            </div>
          </Transition>
          <div className="row justify-content-center align-items-center SearchResults-more-container">
            <div className="col-sm-6 col-sm-offset-3">
              <LoadMoreButton
                shown={criteriaStore.page * limit + limit}
                count={count}
                disabled={loading || error}
                onClick={() => {
                  criteriaStore.setPage(criteriaStore.page + 1);
                  fetchMore(fetchMoreHandler({criteriaStore}))
                }}
              />
            </div>
          </div>
        </>
      }}
    </LoadingDelay>
  </div>
);

export default withRouter(
  withStores(
    withOffersQuery(
      withStores(
        observer(SearchResults),
      ),
    ),
  ),
);
