import React from 'react';
import {debounce, isFunction, pick} from 'lodash';

import './style.scss';

export const ButtonOptions = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
};

export const BUTTON_PRIMARY = 'primary';
export const BUTTON_SECONDARY = 'secondary';

export default class Button extends React.Component {
  static defaultProps = {
    type: ButtonOptions.BUTTON,
    variant: BUTTON_PRIMARY,
    onClick: null,
    disabled: false,
    clickWait: 100,
    className: '',
  };

  static allowedProperties = [
    'disabled',
    'type',
    'className',
    'onClick',
    'onDoubleClick',
    'onTouchEnd',
  ];

  constructor(props, context) {
    super(props, context);
    this.onClickDebounced = debounce(
      this.onClickDebounced.bind(this),
      props.clickWait,
      {
        maxWait: props.clickWait,
        leading: true,
        trailing: false,
      }
    );
  }

  onClickDebounced(e) {
    e.preventDefault();
    e.stopPropagation();
    const {onClick, disabled} = this.props;
    if (!disabled && isFunction(onClick)) {
      onClick();
    }
  }

  getProps() {
    const props = Object.assign({}, this.props, {
      onClick: this.onClickDebounced,
      onDoubleClick: this.onClickDebounced,
      // onTouchEnd: this.onClickDebounced,
      className: `Button Button--${this.props.type} Button--${this.props.variant} ${this.props.className}`
    });

    return pick(props, Button.allowedProperties);
  }

  render() {
    return (
      <button {...this.getProps()}>{this.props.children}</button>
    );
  }
}
