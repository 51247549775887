import React, { useState } from 'react';
import classNames from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

import Search from '../Search'
import HeadingImage from "../../molecules/HeadingImage";

import './style.scss';

function HomeSearch({intl}) {
  const [isOpen, setOpen] = useState(false);

  function open() {
    setOpen(true);
  }

  function close() {
    setOpen(false);
  }

  return <div className={classNames("HomeSearch", {
    'HomeSearch--inactive': !isOpen,
  })}>
    <HeadingImage>
      <Search
        onFocus={open.bind(this)}
        onEscape={close.bind(this)}
        placeholder={
          intl.formatMessage({
            id: 'organisms.home_search.placeholder',
            defaultMessage: 'Suche nach Themen, Titeln, Stichwörtern...',
          })
        }
        enablePredefinedFilters={false}
      />
      <h1 className="HomeSearch-title">
        <FormattedMessage
          id="organisms.home_search.title"
          defaultMessage="Wir bringen die Wirtschaft in die Schule."/>
      </h1>
    </HeadingImage>
  </div>
}

export default injectIntl(HomeSearch);
