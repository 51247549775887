import React, {useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import classNames from 'classnames';
import useForm from 'react-hook-form';
import {useMutation} from '@apollo/react-hooks';

import {
  TextField,
  Select,
} from '../../molecules/Form';
import OfferButtons from "../../molecules/OfferButtons";
import Button from "../../atoms/Button";
import {SubmitOrder, createSubmitOrderMutation, withClient, drupalClient} from "../../../api";

import './style.scss';

function OrderForm({intl, isActive = false, title = null, offerId = null, onSubmitCallback = () => {}}) {
  const [sending, setSending] = useState(false);
  const [submitOrder, {data}] = useMutation(SubmitOrder, {
    client: drupalClient,
    onCompleted: (data) => {
      setSending(false);
      return data;
    },
  });

  const {register, handleSubmit, watch, setValue, errors} = useForm({
    defaultValues: {
      quantity: '1',
      title: '',
    },
  });

  const {submitOrder: {violations, entity}} = data || { submitOrder: { violations: []}};

  if (violations.length === 0 && entity) {
    onSubmitCallback();
  }


  function onSelectChange(name) {
    return function (event) {
      setValue(name, event.target.value);
    };
  }

  function onSubmit(data) {
    if (sending) {
      return;
    }

    setSending(true);
    const submitOrderMutation = createSubmitOrderMutation({...data, offer: offerId});
    submitOrder({variables: {submitOrder: submitOrderMutation}});
  }

  const isValid = (field) => {
    const isEmpty = errors && errors[field] && errors[field].type === 'required';
    const isValid = violations && violations.filter((violation) => {
      return violation.path.includes(field);
    }).length === 0;

    return !isEmpty && isValid;
  };

  const getViolation = (field) => {
    if (isValid(field)) {
      return null;
    }

    if (violations && violations.length) {
      for (const violation of violations) {
        if (violation.path.includes(field)) {
          return violation.message;
        }
      }
    }

    return null;
  };

  register({name: 'quantity', required: true, min: {value: 1}});

  return <form
    className={classNames(`OrderForm container`, {'OrderForm--active': isActive && offerId})}
    onSubmit={handleSubmit(onSubmit)}
  >
    <div className="row">
      <div className="col">
        <h2 className="OrderForm-subtitle">
          <FormattedMessage
            id="organisms.order_form.subtitle"
            defaultMessage="Bestellformular"
          />
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col">
        <h2 className="OrderForm-title">
          { !title && (
            <FormattedMessage
              id="organisms.order_form.title"
              defaultMessage="DAS VEREINIGTE KÖNIGREICH UND DIE EU"
            />
          ) }
          { !!title && (title) }
        </h2>
      </div>
    </div>

    <div className="row">
      <div className="col-sm-12 col-md-6">
        <Select
          native
          name="quantity"
          id="quantity"
          required={true}
          error={!isValid('quantity')}
          value={watch('quantity')}
          label={intl.formatMessage({id: 'organisms.order_form.quantity.label'})}
          onChange={onSelectChange('quantity')}
          autoFocus={true}
        >
          {[...Array(20).keys()].map((key) => {
            const uniqueKey = key + 1;
            return <option value={uniqueKey} key={uniqueKey}>{uniqueKey}</option>;
          })}
        </Select>
        {getViolation('quantity')}
      </div>
      <div className="col-sm-12 col-md-6">
        <TextField
          name="email"
          id="email"
          type="email"
          required={true}
          error={!isValid('email')}
          helperText={<span>{getViolation('email')}</span>}
          label={intl.formatMessage({id: 'organisms.order_form.email.label'})}
          inputRef={register({required: true})}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-sm-12 col-md-6">
        <TextField
          name="first_name"
          id="first_name"
          error={!isValid('first_name')}
          required={true}
          helperText={<span>{getViolation('first_name')}</span>}
          label={intl.formatMessage({id: 'organisms.order_form.first_name.label'})}
          inputRef={register({required: true})}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <TextField
          name="last_name"
          id="last_name"
          required={true}
          helperText={<span>{getViolation('last_name')}</span>}
          error={!isValid('last_name')}
          label={intl.formatMessage({id: 'organisms.order_form.last_name.label'})}
          inputRef={register({required: true})}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-sm-12 col-md-6">
        <TextField
          id="title"
          name="title"
          required={false}
          error={!isValid('title')}
          helperText={<span>{getViolation('title')}</span>}
          label={intl.formatMessage({id: 'organisms.order_form.title.label'})}
          inputRef={register({required: false})}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <TextField
          name="school"
          id="school"
          error={!isValid('school')}
          required={false}
          helperText={<span>{getViolation('school')}</span>}
          label={intl.formatMessage({id: 'organisms.order_form.school.label'})}
          inputRef={register({required: false})}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-sm-12 col-md-6">
        <TextField
          id="street"
          name="street"
          required={true}
          helperText={<span>{getViolation('street')}</span>}
          error={!isValid('street')}
          label={intl.formatMessage({id: 'organisms.order_form.street.label'})}
          inputRef={register({required: true})}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <TextField
          id="address_number"
          name="address_number"
          required={true}
          helperText={<span>{getViolation('address_number')}</span>}
          error={!isValid('address_number')}
          label={intl.formatMessage({id: 'organisms.order_form.address_number.label'})}
          inputRef={register({required: true})}
        />
      </div>
    </div>

    <div className="row">
      <div className="col-sm-12 col-md-6">
        <TextField
          id="zip"
          name="zip"
          required={true}
          helperText={<span>{getViolation('zip')}</span>}
          error={!isValid('zip')}
          label={intl.formatMessage({id: 'organisms.order_form.zip.label'})}
          inputRef={register({required: true})}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <TextField
          id="city"
          name="city"
          required={true}
          error={!isValid('city')}
          helperText={<span>{getViolation('city')}</span>}
          label={intl.formatMessage({id: 'organisms.order_form.city.label'})}
          inputRef={register({required: true})}
        />
      </div>
    </div>

    <div className="row">
      <div className="col">
        <p className="OrderForm-legend">
          <FormattedMessage
            id="organisms.order_form.required_fields"
            defaultMessage="* Pflichtfelder"
          />
        </p>
      </div>
    </div>

    <OfferButtons className="OrderForm-buttons">
      <Button variant="secondary" type="submit" className="OrderForm-submit" onClick={handleSubmit(onSubmit)} disabled={sending}>
        <FormattedMessage
          id="organisms.order_form.submit"
          defaultMessage="Absenden"
        />
      </Button>
    </OfferButtons>
  </form>
}

export default withClient(injectIntl(OrderForm));
