import React from "react";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";

import router from "../../../routes";
import List from "../List";
import ArrowedButton, {PLACEMENT_BEFORE} from "../ArrowedButton";
import {sanitizeHtml} from "../../../utils/strings";

const SingleOffer = withRouter(({item, history}) => {
  const onClick = ({id}) => {
    return () => {
      history.push(router.generate(
        'offer',
        {id},
      ));
    };
  };

  return <li className="Nav-item">
    <ArrowedButton onClick={onClick(item)} placement={PLACEMENT_BEFORE}>
      <span dangerouslySetInnerHTML={{__html: sanitizeHtml(item.label)}} />
    </ArrowedButton>
  </li>;
});

export const OfferList = ({offers, className = ''}) => {
  return <List children={offers.map((item) => <SingleOffer item={item}/>)} className={className}/>
};

export default observer(OfferList);
