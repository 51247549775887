/**
 * @param {String[]} topics
 * @param {String[]} tags
 * @param {String[]} years
 * @param {String} title
 * @param {String[]} excludedNodes
 * @returns {Object}
 */
export function getOffersConditions(
  {
    topics = [],
    tags = [],
    years = [],
    title = null,
    excludedNodes = [],
  }
) {
  const groups = []
  const mainConditions = [
    {field: "type.target_id", value: ['page'], operator: 'EQUAL'},
    {field: "status", operator: 'EQUAL', value: ["1"]}
  ];



  if (excludedNodes.length) {
    mainConditions.push({field: "nid", value: excludedNodes, operator: 'NOT_IN'});
    // groups.forEach((group) => {
    //   group.conditions.push({field: "nid", value: excludedNodes, operator: 'NOT_IN'});
    // });
  }

  if (title && title.length) {
    mainConditions.push({field: "title", value: [`${title}`], operator: 'LIKE'});
    // groups.forEach((group) => {
    //   group.conditions.push({field: "title", value: [`${title}`], operator: 'LIKE'});
    // });
  }

  if (topics.length) {
    groups.push(
      {
        conjunction: 'AND',
        conditions: [
          ...mainConditions,
          {
            field: "field_categories.entity.tid",
            value: topics,
            operator: 'IN'
          },
        ],
      },
    );
  }

  if (tags.length) {
    tags.forEach((tag) => {
      groups.push(
        {
          conjunction: 'AND',
          conditions: [
            ...mainConditions,
            {
              field: "field_tags.entity.tid",
              value: [tag],
              operator: 'EQUAL'
            },
          ],
        },
      );
    });
  }

  if (years.length) {
    years.forEach((year) => {
      groups.push(
        {
          conjunction: 'AND',
          conditions: [
            ...mainConditions,
            {
              field: "field_years.entity.tid",
              value: [year],
              operator: 'EQUAL'
            },
          ],
        },
      );
    });
  }


  return {
    conjunction: 'AND',
    groups,
    conditions: groups.length ? [] : mainConditions,
  };
}

/**
 * @param {String[]} topics
 * @param {String[]} tags
 * @param {String[]} excludedNodes
 * @param {String[]} excludedTopics
 * @param {String[]} excludedTags
 * @returns {Object[]}
 */
export function getRecommendedOffersConditions(
  topics = [],
  tags = [],
  excludedNodes = [],
  excludedTopics = [],
  excludedTags = []
) {
  const conditions = [
    {field: "type.target_id", value: ['page'], operator: 'EQUAL'},
    {field: "status", operator: 'EQUAL', value: ["1"]}
  ];

  if (topics.length) {
    conditions.push(
      {field: "field_categories.entity.tid", value: topics, operator: 'IN'},
    )
  }

  if (tags.length) {
    conditions.push(
      {field: "field_tags.entity.tid", value: tags, operator: 'IN'},
    );
  }

  if (excludedNodes.length) {
    conditions.push(
      {field: "nid", value: excludedNodes, operator: 'NOT_IN'},
    )
  }

  if (excludedTopics.length) {
    conditions.push(
      {field: "field_categories.entity.tid", value: excludedTopics, operator: 'NOT_IN'},
    )
  }

  if (excludedTags.length) {
    conditions.push(
      {field: "field_tags.entity.tid", value: excludedTags, operator: 'NOT_IN'},
    );
  }

  return conditions;
}
