import React from 'react';
import {withRouter} from "react-router-dom";

import {withStores} from "../../../stores";
import {generate} from "../../../routes";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import {sanitizeHtml} from "../../../utils/strings";

import './style.scss';

export const TYPE_TAG = 1;
export const TYPE_TOPIC = 2;
export const TYPE_PAGE = 3;
export const TYPE_YEAR = 4;

/**
 *
 * @param {CriteriaStore} criteriaStore
 * @param history
 * @param location
 * @param item
 * @param type
 * @constructor
 */
function SearchSelectedCriteria({criteriaStore, history, location, item, type}) {
  function onClick() {
    switch (type) {
      case TYPE_TAG:
        criteriaStore.removeTags(item.id);
        criteriaStore.setPage(null);
        break;
      case TYPE_TOPIC:
        criteriaStore.removeTopics(item.id);
        criteriaStore.setPage(null);
        break;
      case TYPE_YEAR:
        criteriaStore.removeYears(item.id);
        criteriaStore.setPage(null);
        break;
      case TYPE_PAGE:
        criteriaStore.removePages(item.id);
        history.push(generate('search', criteriaStore.rawParams));
        return;
      default:
        return false;
    }

    history.push(Object.assign(
      location,
      {search: criteriaStore.queryParams}
    ));
  }

  return (
    <Button onClick={onClick} className={"SearchSelectedCriteria"}>
      <span className={"SearchSelectedCriteria-label"} dangerouslySetInnerHTML={{__html: sanitizeHtml(item.label)}}/>
      <Icon icon={"close"}/>
    </Button>
  );
}

export default withRouter(
  withStores(SearchSelectedCriteria),
);
