import sanitize from 'sanitize-html';

export const ACCEPTABLE_TITLE_TAGS = ['wbr'];

/**
 * @param {String} text
 * @param {Array<String>} acceptableTags
 * @return {String}
 */
export function sanitizeHtml(text, acceptableTags = ACCEPTABLE_TITLE_TAGS) {
  return sanitize(text, {
    allowedTags: ACCEPTABLE_TITLE_TAGS,
  });
}
