import React from 'react';
import {graphql} from "react-apollo";
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";
import {get} from "lodash";

import {GetOffers, getRecommendedOffersConditions, mapNodeCount, mapNodeEntities, mapNodeToMenu} from "../../../api";
import OfferList from "../OfferList";
import Loader from "../../atoms/Loader";

import './style.scss';

function OfferSuggestions({entities, loading, className = ''}) {
  if (loading) {
    return <Loader/>
  }

  if (!entities.length) {
    return <p>
      <FormattedMessage id="molecules.offer_suggestions.no_results"/>
    </p>
  }

  return <OfferList offers={entities} className={`OfferSuggestions ${className}`}/>;
}

function getCategories(offer) {
  return get(offer, 'fieldCategories.entities', [])
    .map(({ tid }) => tid);
}

function getOfferSuggetionsConditions(offer) {
  return {
    conditions: getRecommendedOffersConditions(
      getCategories(offer),
      [],
      [offer.id],
    ),
  };
}

const withOffersQuery = graphql(GetOffers, {
  options: ({offer, className = ''}) => ({
    className,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getOfferSuggetionsConditions(offer),
      limit: 5,
      offset: 0,
    },
  }),
  props: ({data, ownProps: {className = ''}}) => ({
    className,
    loading: data.loading,
    entities: mapNodeEntities(data, mapNodeToMenu),
    count: mapNodeCount(data),
  }),
});


export default withOffersQuery(
  observer(OfferSuggestions),
);
