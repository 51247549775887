import React from 'react';
import {withRouter} from "react-router-dom";

import {withStores} from "../../../stores";
import Tag from "../../atoms/Tag";
import Button from "../../atoms/Button";
import router from "../../../routes";

import './style.scss';

export const TYPE_TOPIC = 1;
export const TYPE_TAG = 2;
export const TYPE_YEAR = 3;

function OfferTag({tag}) {
  return <Tag>{tag.name}</Tag>;
}

function handleOnClick({criteriaStore, id, type}) {
  criteriaStore.clear();
  switch (type) {
    case TYPE_TOPIC:
      criteriaStore.addTopics(id);
      break;
    case TYPE_TAG:
      criteriaStore.addTags(id);
      break;
    case TYPE_YEAR:
      criteriaStore.addYears(id);
      break;
    default:
      break;
  }
}

function OfferTagClickable({tag, criteriaStore, history, type = null}) {
  if (!(tag && tag.tid)) {
    return null;
  }

  function onClick(id, type) {
    return () => {
      handleOnClick({criteriaStore, id, type});
      history.push(router.generate(
        'search',
        criteriaStore.rawParams,
      ));
    };
  }

  return <Button key={tag.tid} onClick={onClick(tag.tid, type)}>
    <OfferTag tag={tag}/>
  </Button>
}

function resolveType({tags, years, categories, element}) {
  if (tags.includes(element)) {
    return TYPE_TAG;
  }
  if (years.includes(element)) {
    return TYPE_YEAR;
  }
  if (categories.includes(element)) {
    return TYPE_TOPIC;
  }

  return null;
}

function OfferTags({tags = [], categories = [], years = [], limit, clickable = true, criteriaStore, history}) {
  let all = [...years, ...categories, ...tags];
  if (limit) {
    all = all.slice(0, limit);
  }

  return <span className={`OfferTags`}>
    {
      all.map((tag, key) => {
        if (!clickable) {
          return <OfferTag tag={tag} key={key}/>
        }

        return <OfferTagClickable
          key={key}
          tag={tag}
          type={resolveType({tags, categories, years, element: tag})}
          criteriaStore={criteriaStore}
          history={history}/>;
      })}
  </span>
}

export default withRouter(
  withStores(OfferTags),
);
