import React from 'react';
import {Link} from "react-router-dom";
import {observer} from "mobx-react";

import './style.scss';

function FooterMenu({items = [], header = null, className = ''}) {
  const links = [];
  let key = 0;
  for (const item of items) {
    links.push(
      <li className={"FooterMenu-item"} key={key++}>
        { item.external && (
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            {item.label}
          </a>
        ) }
        { !item.external && (
          <Link to={item.url}>
            {item.label}
          </Link>
        ) }
      </li>
    )
  }

  return <div className={`FooterMenu ${className}`}>
    <ul className={"FooterMenu-list"}>
      {links}
    </ul>
  </div>;
}

export default observer(FooterMenu);
