import React from 'react';

import Icon from "../../atoms/Icon";

import './style.scss';

function SearchIcon({onClick = () => {}}) {
  return (
    <span className={'SearchIcon'} onClick={onClick}>
      <Icon icon={'search'}/>
    </span>
  );
}

export default SearchIcon;
