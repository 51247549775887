import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import Home from './components/pages/Home';
import StaticPage from './components/pages/StaticPage';
import NotFound from './components/pages/NotFound';
import ScrollToTop from './components/atoms/ScrollToTop';
import Search from "./components/pages/Search";
import Offer from "./components/pages/Offer";
import Footer from "./components/organisms/Footer";
import Header from "./components/organisms/Header";

export const browserHistory = createBrowserHistory();

function AppRouter() {
  return (
    <Router history={browserHistory}>
      <ScrollToTop>
        <Header/>
        <div className={"RouterContainer"}>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/offers/:id" component={Offer}/>
            <Route path="/search" component={Search}/>
            <Route path="/404" component={NotFound}/>
            <Route path="*" component={StaticPage}/>
          </Switch>
        </div>
        <Footer/>
      </ScrollToTop>
    </Router>
  );
}

export default AppRouter;
