import React from "react";
import {ApolloClient} from "apollo-boost";
import { InMemoryCache, HttpLink, IntrospectionFragmentMatcher } from 'apollo-boost';
import {ApolloProvider} from "react-apollo";

import introspectionQueryResultData from './fragmentTypes.json';
import {GRAPHQL_URL} from "./config";

const link = new HttpLink({
  uri: GRAPHQL_URL,
});

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

export const drupalClient = new ApolloClient({
  uri: process.env.GRAPHQL_URL || '/graphql',
  cache,
  link,
});

export default {
  drupalClient,
};

export const withClient = (PassedComponent) => {
  return function withClient(props) {
    return <ApolloProvider client={drupalClient}>
      <PassedComponent {...props}/>
    </ApolloProvider>;
  };
};

export * from './queries';
export * from './mappers';
export * from './queryBuilders';
export * from './config';
export * from './mutationFactories';
