import React from 'react';
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {graphql} from "react-apollo";
import {get} from "lodash";
import classNames from 'classnames';

import {withStores} from "../../../stores";
import {GetBanners, mapNodeEntities} from "../../../api";
import Loader from "../../atoms/Loader";
import LoadingDelay from "../../atoms/LoadingDelay";
import {trackLink} from "../../../utils/withTracker";

import './style.scss';

const MAX_SEARCH_ENTRIES = 8;
class Banners extends React.Component {
  render() {
    const { banners, loading } = this.props;
    const rows = [];

    for (const banner of banners) {
      const thumbnailUrl = get(banner, 'fieldImage.entity.fieldMediaImage.thumbnail.url', null);
      const thumbnailAlt = get(banner, 'fieldImage.entity.fieldMediaImage.alt', null);
      const linkAttributes = this.bannerLinkAttributes(banner);
      const hasImage = thumbnailUrl && thumbnailAlt;
      const hasTitle = !banner.titleHidden;

      const bannerElement = <div className={'container'} key={banner.entityId}>
        <div className={classNames('row Banner', { 'Banner--withImage': hasImage })} key={banner.entityId}>
          <div
            className={`${hasImage ? 'col-sm-12 col-md-8 col-lg-9 ' : 'col-12'}`}>
            { hasTitle && (<span className={'Banner-title'}>{banner.title}</span>) }
            <div dangerouslySetInnerHTML={{__html: banner.body.value}} className={'Banner-body'}/>
          </div>
          { hasImage && (
            <div
              className={'col-sm-12 col-md-4 col-lg-3'}>
              <div className={' Banner-image '}>
                <img src={thumbnailUrl} alt={banner.title}/>
              </div>
            </div>
          )}
        </div>
      </div>;

      rows.push(
        this.wrapWithLinkIfPossible(bannerElement, linkAttributes)
      )
    }
    return <div className={'Banners'}>
      <LoadingDelay check={loading} delay={250}>
        {({isLoading, isDelaying}) => {
          if (isLoading || isDelaying) {
            return <Loader/>;
          }

          return rows;
        }}
      </LoadingDelay>
    </div>
  }

  /**
   *
   * @param {JSX.Element} element
   * @param props
   * @return {JSX.Element|*}
   */
  wrapWithLinkIfPossible(element, props) {
    if (props.title && props.href) {
      return (
        <a {...props} key={element.key} target="_blank" rel="noreferrer noopener" onClick={trackLink}>
          {element}
        </a>
      );
    }

    return element;
  }

  bannerLinkAttributes(banner) {
    const href = get(banner, 'fieldLink.uri');
    const title = get(banner, 'fieldLink.title');

    return {
      href,
      title,
    };
  }
}

const withBanners = graphql(GetBanners, {
  options: ({taxonomy, groups = []}) => {
    return {
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          conditions: [
            {
              field: "type.target_id",
              value: ["banner"],
              operator: "EQUAL"
            },
            {
              field: "status",
              value: ["1"],
              operator: "EQUAL"
            }
          ]
        },
        taxonomy,
        limit: MAX_SEARCH_ENTRIES,
        offset: 0,
      },
    };
  },
  props: ({data: {error, loading, nodeQuery}}) => ({
    loading,
    error,
    banners: mapNodeEntities({nodeQuery}, item => item),
    count: nodeQuery ? nodeQuery.count : 0,
  })
});

export default withRouter(
  withBanners(
    withStores(
      observer(
        Banners,
      ),
    ),
  ),
);
