import React from 'react';
import {FormattedMessage} from "react-intl";

import Image from "../../atoms/Image";

import './style.scss';

import sparkasse from './sparkasse.png';
import wko from './wko.svg';
import schule from './schule.svg';

function Partners () {
  return <div className="Partners">
    <div className="Partners-title">
      <FormattedMessage
        className="Partners-title"
        id="molecules.partners.title"
        defaultMessage="Project partners"
      />:
    </div>
    <ul className="Partners-list">
      <li className="Partners-list-item wko">
        <a href="https://www.wko.at/" rel="noopener noreferrer" target="_blank">
          <Image src={wko} alt="WKO"/>
        </a>
      </li>
      <li className="Partners-list-item sparkasse">
        <a href="http://www.sparkasse.at/" rel="noopener noreferrer" target="_blank">
          <Image src={sparkasse} alt="Sparkasse Verband Österreich "/>
        </a>
      </li>
      <li className="Partners-list-item schule">
        <a href="https://www.wko.at/site/schule-trifft-wirtschaft/start.html" rel="noopener noreferrer" target="_blank">
          <Image src={schule} alt="Schule trifft Wirtschaft"/>
        </a>
      </li>
    </ul>
  </div>
}

export default Partners;
