import React from 'react';

import './style.scss';

function HeadingImage({children}) {
  return <div className="HeadingImage">
    {children}
  </div>
}

export default HeadingImage;
