import React from 'react';

import './Loader.scss';

export const CubeGrid = () => {
  return <div className="sk-cube-grid">
    <div className="sk-cube sk-cube1"/>
    <div className="sk-cube sk-cube2"/>
    <div className="sk-cube sk-cube3"/>
    <div className="sk-cube sk-cube4"/>
    <div className="sk-cube sk-cube5"/>
    <div className="sk-cube sk-cube6"/>
    <div className="sk-cube sk-cube7"/>
    <div className="sk-cube sk-cube8"/>
    <div className="sk-cube sk-cube9"/>
  </div>;
};

export const Spinner = () => {
  return <div className="spinner">
    <div className="bounce1"/>
    <div className="bounce2"/>
    <div className="bounce3"/>
  </div>
};

export default Spinner;
