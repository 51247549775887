import React from 'react';

import './style.scss';

export const ARROW_UP = 'up';
export const ARROW_DOWN = 'down';
export const ARROW_LEFT = 'left';
export const ARROW_RIGHT = 'right';

function Arrow({variant = ARROW_RIGHT}) {
  return <span className={`Arrow Arrow--${variant}`}/>
}

export default Arrow;
