import React from 'react';

import './style.scss';

const Image = ({src, alt, className = '', width = null, height = null}) => {
  if (!src || !alt) {
    throw Error('Both `alt` and `src` props are required');
  }

  return <img className={`Image ${className}`} src={src} alt={alt} width={width} height={height}/>;
};

export default Image
