/**
 * @param arr
 * @param size
 * @returns {*[]}
 */
export const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
  arr.slice(i * size, i * size + size)
);

/**
 * @param arr
 * @returns {*[]}
 */
export const split = (arr) => chunk(arr, Math.ceil(arr.length / 2));

export default {
  chunk,
  split,
};
