import React from 'react';
import {FormattedMessage} from "react-intl";
import {observer} from "mobx-react";
import {graphql} from "react-apollo";

import Loader from "../../atoms/Loader";
import {GetTermsByGroups, mapTaxonomyCount} from "../../../api";
import {mapTags} from "../../../stores/TagsStore";
import TagList from "../../molecules/TagList";
import TopicList from "../../molecules/TopicList";

import './style.scss';

const MAX_SEARCH_ENTRIES = 8;

const withPredefinedTerms = graphql(GetTermsByGroups, {
  options: ({taxonomy, groups = []}) => {
    return {
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        groups,
        taxonomy,
        limit: MAX_SEARCH_ENTRIES,
        offset: 0,
      },
    };
  },
  props: ({data}) => ({
    loading: data.loading,
    count: mapTaxonomyCount(data),
    entities: mapTags({data}),
  }),
});

const withGroups = (WrappedComponent, {groups, taxonomy}) => {
  return function withGroups(props) {
    return <WrappedComponent {...props} groups={groups} taxonomy={taxonomy}/>
  }
};

const PredefinedSchoolTags = withGroups(
  withPredefinedTerms(
    observer(({entities, count, loading}) => {
      if (loading) {
        return <Loader/>;
      }

      if (!entities.length) {
        return null;
      }

      return <TagList tags={entities} withCounters={false} className="d-flex align-items-center"/>;
    })
  ),
  {
    groups: ['school'],
    taxonomy: 'tags',
  }
);

const PredefinedEducationTags = withGroups(
  withPredefinedTerms(
    observer(({entities, count, loading}) => {
      if (loading) {
        return <Loader/>;
      }

      if (!entities.length) {
        return null;
      }

      return <TagList tags={entities} withCounters={false} className="d-flex align-items-center"/>;
    }),
  ),
  {
    groups: ['education'],
    taxonomy: 'tags',
  }
);


const PredefinedTopics = withGroups(
  withPredefinedTerms(
    observer(({entities, loading}) => {
      if (loading) {
        return <Loader/>;
      }

      if (!entities.length) {
        return null;
      }

      return <TopicList topics={entities} withCounters={false} className="d-flex align-items-center"/>;
    }),
  ),
  {
    groups: ['topic'],
    taxonomy: 'category',
  }
);

function PredefinedFilters() {
  return <div className="PredefinedFilters">
    <div className="row d-flex align-items-center">
      <div className="col-3 col-md-2 col-lg-1 ">
        <FormattedMessage
          id="organisms.search.predefined_topics"
          defaultMessage="Bereich"
        />:
      </div>
      <div className="col-9 col-md-10 col-lg-11 PredefinedFilters-tags">
        <PredefinedTopics/>
      </div>
    </div>
    <div className="row d-flex align-items-center">
      <div className="col-3 col-md-2 col-lg-1 ">
        <FormattedMessage
          id="organisms.search.predefined_school_tags"
          defaultMessage="Schulstufe"
        />:
      </div>
      <div className="col-9 col-md-10 col-lg-11 PredefinedFilters-tags">
        <PredefinedSchoolTags/>
      </div>
    </div>
    <div className="row d-flex align-items-center">
      <div className="col-3 col-md-2 col-lg-1 ">
        <FormattedMessage
          id="organisms.search.predefined_education_tags"
          defaultMessage="Didaktik"
        />:
      </div>
      <div className="col-9 col-md-10 col-lg-11 PredefinedFilters-tags">
        <PredefinedEducationTags/>
      </div>
    </div>
  </div>
}

export default PredefinedFilters;
