import React from 'react';

import './style.scss';

function Triangles({className = 'col-sm-12 col-md-6 offset-md-3'}) {
  return <div className="Triangles">
    <div className="row">
      <div className={`Triangles-position ${className}`}>
        <div className={`Triangles-image`}/>
      </div>
    </div>
  </div>;
}

export default Triangles;
