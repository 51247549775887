import React from 'react';
import {FormattedMessage} from "react-intl";

import {SUBSCRIPTION, NEWSLETTER} from "../../../utils/staticPages";

import './style.scss';

function Subscriptions () {
  return <div className="Subscriptions">
    <div className="container  Subscriptions-container">
      <div className="row align-items-stretch">
        <div className="Subscriptions-column">
          <div className="Subscriptions-speaker"/>
          <p className="Subscriptions-header">
            <FormattedMessage
              id="organisms.subscriptions.subscribe_header"
              defaultMessage="Get regular information about the current offers of the AWS."
            />
          </p>
          <p>
            <a className="Subscriptions-button" href={SUBSCRIPTION} rel="noopener noreferrer" target="_blank">
              <FormattedMessage
                id="organisms.subscriptions.subscribe"
                defaultMessage="Subscribe Newsletter"
              />
            </a>
          </p>
        </div>
        <div className="Subscriptions-column Subscriptions-divider"/>
        <div className="Subscriptions-column">
          <div className="Subscriptions-book"/>
          <p className="Subscriptions-header">
            <FormattedMessage
              id="organisms.subscriptions.publish_header"
              defaultMessage="Erhalten Sie kostenlos die neuen AWS-Printausgaben."
            />
          </p>
          <p>
            <a className="Subscriptions-button" href={NEWSLETTER} rel="noopener noreferrer" target="_blank">
              <FormattedMessage
                id="organisms.subscriptions.publish"
                defaultMessage="Send new publications"
              />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

}

export default Subscriptions;
