import React from 'react';
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {get} from "lodash";
import {injectIntl} from "react-intl";

import Image from "../../atoms/Image";
import {generate} from "../../../routes";
import NewIcon from "../../atoms/NewIcon";
import {sanitizeHtml} from "../../../utils/strings";

import './style.scss';

const OfferCard = ({offer, intl, className = '', children = null}) => {
  const url = generate('offer', {id: offer.entityId});
  const thumbnailUrl = get(offer, 'fieldThumbnail.entity.fieldMediaImage.thumbnail.url', null);
  const thumbnailAlt = get(offer, 'fieldThumbnail.entity.fieldMediaImage.alt', null);

  return <Link to={url} className={`OfferCard ${className}`}>
    <div className="OfferCard-cover">
      {!!thumbnailUrl && !!thumbnailAlt && (
        <Image src={thumbnailUrl} alt={thumbnailAlt}/>
      )}
    </div>
    <div className="OfferCard-title">
      <span className="ellipsis" dangerouslySetInnerHTML={{__html: sanitizeHtml(offer.title)}}/>
    </div>
    {offer.isNew && (<div className="OfferCard-new">
      <NewIcon label={intl.formatMessage({
        id: 'organisms.offer_card.new',
        defaultMessage: 'New'
      })}/>
    </div>)}
    {children}
  </Link>
};


export default injectIntl(
  observer(OfferCard),
);
