import React from 'react';
import classNames from 'classnames';

import './style.scss';

const Hamburger = ({active, label = ''}) => {
  return <span className={classNames('Hamburger hamburger hamburger--squeeze', {'is-active': active})}>
    <span className="hamburger-box">
      <span className="hamburger-inner"/>
    </span>
    <span className="Hamburger-label">{label}</span>
  </span>;
};

export default Hamburger;
