import React from 'react';

import './style.scss';
import {FormattedHTMLMessage} from "react-intl";

function Copyright () {
  const now = new Date();

  return <p className="Copyright">
    <FormattedHTMLMessage
      id="molecules.copyright.text"
      defaultMessage="&copy; Copyright {year} AWS - Arbeitsgemeinschaft Wirtschaft und Schule"
      description="Copyright text"
      values={{
        year: now.getFullYear(),
      }}
    />
  </p>
}

export default Copyright;
