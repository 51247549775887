import React from 'react';

import './style.scss';

const Tag = ({children}) => {
  return <span className="Tag">
    <span className="Tag-icon awsicon-tag"/>
    {children}
  </span>;
};

export default Tag;
