import React from 'react';
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {graphql} from "react-apollo";

import {withStores} from "../../../stores";
import OfferDetails from "../../organisms/OfferDetails";
import {GetOffer} from "../../../api";
import withTracker from "../../../utils/withTracker";
import LoadingDelay from "../../atoms/LoadingDelay";

import './style.scss'
import Loader from "../../atoms/Loader";

function Offer(props) {
  return <LoadingDelay check={props.loading && !props.offer} delay={600}>
    {({isLoading, isDelaying}) => {
      if (isLoading || isDelaying) {
        return <Loader/>
      }

      return <div className="Offer">
        <div className="container">
          <div className="row">
            <OfferDetails {...props}/>
          </div>
        </div>
      </div>;
    }}
  </LoadingDelay>;
}


const withOfferQuery = graphql(GetOffer, {
  options: ({match}) => ({
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    variables: {
      id: match.params.id,
    }
  }),
  props: ({data: {error, loading, nodeQuery}}) => ({
    loading,
    error,
    offer: nodeQuery ? nodeQuery.count ? nodeQuery.entities[0] : null : null,
  }),
});

export default withOfferQuery(
  withRouter(
    withTracker(
      withStores(
        observer(Offer),
      ),
    ),
  ),
);
