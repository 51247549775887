import React from 'react';

import './style.scss';

function NewIcon({label}) {
  return <span className={"NewIcon"}>
    <span className={"NewIcon-arrow"}/>
    <span className={"NewIcon-label"}>{label}</span>
  </span>
}

export default NewIcon;
