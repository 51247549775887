import React from 'react';

import './List.scss';

class List extends React.Component {
  renderLinks() {
    if (!this.props.children) {
      return null;
    }

    const items = [];

    let i = 0;
    for (const child of this.props.children) {
      items.push(React.cloneElement(
        child,
        Object.assign({}, child.props, {
          key: i++,
          className: "Nav-item"
        }),
      ));
    }

    return items;
  }

  render() {
    return <ul className={`Nav ${this.props.className || ''}`}>
      {this.renderLinks()}
    </ul>;
  }
}

export default List;
