import React from 'react';

import './style.scss';

export const VARIANT_PRIMARY = 'primary';
export const VARIANT_SECONDARY = 'secondary';

function Divider({variant = VARIANT_PRIMARY}) {
  return <hr className={`Divider Divider--${variant}`}/>
}

export default Divider;
