import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {Select, FormControl, InputLabel, makeStyles, Input} from "@material-ui/core";

import theme from './theme';

const defaultProps = {
  value: '',
  label: null,
  displayEmpty: true,
};

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {},
  selectEmpty: {},
}));

export default function (props = {}) {
  const classes = useStyles();
  const newProps = Object.assign({}, defaultProps, props);

  return <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={newProps.id}>
          {newProps.label}
        </InputLabel>
        <Select
          {...newProps}
          value={newProps.value}
          inputProps={{
            id: newProps.id,
            name: newProps.name,
          }}
          input={
            <Input
              name={newProps.name}
              id={newProps.id}
              ref={newProps.ref}
              value={newProps.value}
            />
          }
          disableUnderline={true}
        />
      </FormControl>
    </div>
  </ThemeProvider>
};
