import React, {useEffect} from 'react';
import {observer} from "mobx-react";
import {Redirect, withRouter} from "react-router-dom";
import {get} from 'lodash';

import Loader from "../../atoms/Loader";
import HeadingImage from "../../molecules/HeadingImage";
import {createSetTitleCallback} from "../../../utils/metadata";
import CookiebotInfobox from "../../molecules/CookiebotInfobox";

import './style.scss';

function StaticPage({page, loading}) {
  useEffect(createSetTitleCallback(page, 'title'));

  if (loading) {
    return <Loader/>
  }

  if (!page) {
    return <Redirect to="/404"/>
  }

  return <div>
    <HeadingImage>
      <h1 className="HomeSearch-title">
        {page.title}
      </h1>
    </HeadingImage>
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-6 offset-md-3">
          {page.body && page.body.summary && (
            <p className="OfferDetails-summary">{page.body.summary}</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 offset-md-3"
             dangerouslySetInnerHTML={{__html: get(page, 'body.value', null)}}/>
      </div>
      {/*<Triangles/>*/}
      <CookiebotInfobox page={page}/>
    </div>
  </div>;
}

export default withRouter(
  observer(StaticPage),
);
