import React from "react";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {get} from "lodash";

import router from "../../../routes";
import BlurableButton from "../BlurableButton";
import List from "../List";
import {withStores} from "../../../stores";

const YearElement = withRouter(withStores(
  ({item, history, criteriaStore}) => {
    const onClick = ({id}) => {
      return () => {
        criteriaStore.addYears(id);
        history.push(router.generate(
          'search',
          criteriaStore.rawParams,
        ));
      };
    };

    return <li className="Nav-item">
      <BlurableButton onClick={onClick(item)}>{item.label} ({get(item, 'years.count', 0)})</BlurableButton>
    </li>;
  }
));

export const YearsList = ({years = []}) => {
  return <List children={years.map((item) => <YearElement item={item}/>)}/>
};

export default observer(YearsList);
