import React from 'react';

import {CriteriaStore} from './CriteriaStore';
import {TopicsStore} from './TopicsStore';
import {TagsStore} from './TagsStore';
import {YearsStore} from './YearsStore';
import {PagesStore} from './PagesStore';

export const stores = {
  criteriaStore: CriteriaStore,
  topicsStore: TopicsStore,
  tagsStore: TagsStore,
  yearsStore: YearsStore,
  pagesStore: PagesStore,
};

export const StoreContext = React.createContext(stores);

/**
 *
 * @returns {{topicsStore: Topics, criteriaStore: Store}}
 */
export const useStores = () => {
  return React.useContext(StoreContext);
};

export const withStores = (PassedComponent) => {
  return function withStoreContext(props) {
    return <StoreContext.Consumer>
      {(stores) => (<PassedComponent {...props} {...stores}/>)}
    </StoreContext.Consumer>;
  };
};

export default {
  stores,
  StoreContext,
  useStores,
  withStores,
};

window.stores = stores;

