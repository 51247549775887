import {observable, computed, action, decorate} from 'mobx';

class Pages {
  isLoading = false;
  entries = new Map();

  get(id) {
    return this.entries.get(`${id}`);
  }

  add(...pages) {
    pages.forEach(page => this.entries.set(`${page.id}`, page));
  }

  remove(...ids) {
    ids.forEach(id => this.entries.delete(`${id}`));
  }

  clear() {
    this.entries.clear();
  }

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  get all() {
    return [...this.entries.values()];
  }


  get ids() {
    return this.all.map(page => page.id);
  }
}

decorate(Pages, {
  entries: observable,
  isLoading: observable,
  startLoading: action,
  stopLoading: action,
  add: action,
  remove: action,
  clear: action,
  all: computed,
  ids: computed,
});

export const PagesStore = new Pages();
