import {observable, computed, action, decorate} from 'mobx';

import {drupalClient, GetTags, mapTaxonomyTermEntities, mapTaxonomyToMenu} from "../api";

export const mapTags = ({data}) => {
  return mapTaxonomyTermEntities(data, mapTaxonomyToMenu);
};

class Tags {
  isLoading = false;
  entries = new Map();

  constructor() {
    this.startLoading();
    drupalClient.query({
      query: GetTags,
      variables: {
        offset: 0,
        limit: 1000,
        name: '%%', // @TODO review if there is better way to get all topics
      },
      fetchPolicy: 'cache-first',
    })
      .then(({data}) => {
        this.add(...mapTags({data}));
      })
      .finally(() => this.stopLoading())
    ;
  }

  get(id) {
    return this.entries.get(id);
  }

  add(...topics) {
    topics.forEach(tag => this.entries.set(tag.id, tag));
  }

  remove(...ids) {
    ids.forEach(id => this.entries.delete(id));
  }

  clear() {
    this.entries.clear();
  }

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  get all() {
    return [...this.entries.values()];
  }

  get ids() {
    return this.all.map(topic => topic.id);
  }
}

decorate(Tags, {
  entries: observable,
  isLoading: observable,
  startLoading: action,
  stopLoading: action,
  add: action,
  remove: action,
  clear: action,
  all: computed,
  ids: computed,
});

export const TagsStore = new Tags();
