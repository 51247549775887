import React from "react";
import {withRouter} from "react-router-dom";

import {useStores} from "../../../stores";
import {generate} from "../../../routes";
import ArrowedButton from "../ArrowedButton";

function ShowMoreCategoryOffers({children, history, id}) {
  const {criteriaStore} = useStores();
  const onClick = () => {
    criteriaStore.clear();
    criteriaStore.addTopics(id);
    history.push(
      generate('search', criteriaStore.rawParams),
    );
  };
  return <ArrowedButton onClick={onClick} className={"SeeMore"}>
    {children}
  </ArrowedButton>
}

export default withRouter(ShowMoreCategoryOffers);
