import {generateStatic, generate} from "../routes";

export const CONTACT = generateStatic('contact');
export const PARTNER = generateStatic('partner');
export const PARTNER_OFFER = generateStatic('partnerangebote');
export const IMPRESSUM = generateStatic('impressum');
export const PRIVACY = generateStatic('datenschutz');
export const TERMS_CONDS = generateStatic('agb');
export const ABOUT = generateStatic('uber-uns');

export const OFFER = generate('search');
export const HOME = generate('home');

export const SUBSCRIPTION = 'https://seu2.cleverreach.com/f/99890-177570/';
export const NEWSLETTER = 'https://seu2.cleverreach.com/f/99890-179781/';
export const YOUTUBE = 'https://www.youtube.com/channel/UCOrsbvDLGEDxRZONu_zFC0A';
export const FACEBOOK = 'https://www.facebook.com/AWSibw/';
export const INSTAGRAM = 'https://www.instagram.com/AWSibw/';


export class MenuElement {
  id = '';
  defaultMessage = '';
  url = '';
  target = '_self';
  external = false;

  static create({id, url, defaultMessage = '', target = '_self', external = false}) {
    const element = new MenuElement();
    Object.assign(element, {
      id,
      url,
      defaultMessage,
      target,
      external,
    });

    return element;
  }
}

/**
 *
 * @type {{offer: MenuElement, partner: MenuElement, about: MenuElement, home: MenuElement}}
 */
export const MainMenu = {
  home: MenuElement.create({
    id: 'molecules.links.homepage',
    defaultMessage: 'Startseite',
    url: HOME,
  }),
  offer: MenuElement.create({
    id: 'molecules.links.offer',
    defaultMessage: 'Angebot',
    url: OFFER,
  }),
  partner: MenuElement.create({
    id: 'molecules.links.partner_offer',
    defaultMessage: 'Partnerangebote',
    url: PARTNER_OFFER,
  }),
  about: MenuElement.create({
    id: 'molecules.links.about',
    defaultMessage: 'Über uns',
    url: ABOUT,
  }),
};

/**
 *
 * @type {{newsletter: MenuElement, contact: MenuElement, about: MenuElement, privacy: MenuElement, impressum: MenuElement}}
 */
export const SecondaryMenu = {
  contact: MenuElement.create({
    id: 'molecules.links.contact',
    defaultMessage: 'Kontakt',
    url: CONTACT,
  }),
  impressum: MenuElement.create({
    id: 'molecules.links.impressum',
    defaultMessage: 'Impressum',
    url: IMPRESSUM,
  }),
  privacy: MenuElement.create({
    id: 'molecules.links.privacy',
    defaultMessage: 'Datenschutz',
    url: PRIVACY,
  }),
  about: MenuElement.create({
    id: 'molecules.links.terms_conds',
    defaultMessage: 'AGB',
    url: TERMS_CONDS,
  }),
};
