import React from 'react';
import {get} from 'lodash';

import {BASE_URL} from "../../../api";
import {actions, trackEvent} from "../../../utils/withTracker";

import './style.scss';

export const TYPE_ATTACHEMENT = 'attachment';
export const TYPE_EXTERNAL = 'external_link';

function resolveDrupalDownloadMedia(id) {
  return `${BASE_URL}/media/${id}/download`;
}

function resolveUrl(button) {
  if (button.entity.type === TYPE_ATTACHEMENT) {
    return resolveDrupalDownloadMedia(button.entity.id);
  }

  if (button.entity.type === TYPE_EXTERNAL) {
    return button.entity.link.value;
  }

  return null;
}

function getButtonsByType(offer = null, type = null) {
  let buttons = [];

  if (!offer) {
    return buttons;
  }

  if (offer.buttons && offer.buttons.length) {
    buttons.push(...offer.buttons);
  }

  if (type) {
    buttons = buttons.filter((button) => button.entity.type === type);
  }

  return buttons;
}

/**
 * @param {String} type
 * @param {String} title
 * @param {Event} e
 */
function onClick(type, title, e) {
  let href = get(e, 'target.href', null);

  if (!href) {
    return;
  }

  trackEvent({
    category: title,
    action: type === TYPE_EXTERNAL ? actions.EXTERNAL : actions.DOWNLOAD,
    label: href,
  });
}

function OfferButtons({offer = null, children = [], type = null, className = ''}) {
  const buttons = [];

  getButtonsByType(offer, type)
    .forEach((button, key) => {
      const title = `${get(button, 'entity.name', '')}`;

      buttons.push(
        <a key={key}
           onClick={onClick.bind(this, type, title)}
           href={resolveUrl(button)}
           className="Button Button--secondary"
           rel="noopener noreferrer"
           target={button.entity.type === TYPE_ATTACHEMENT ? '_self' : '_blank'}
           download={button.entity.type === TYPE_ATTACHEMENT ? button.entity.file.entity.filename : false}
        >
          {button.entity.name}
        </a>)
    });

  return <div className="row">
    <div className="col">
      <div className={`OfferButtons ${className}`}>
        {children}
        {buttons}
      </div>
    </div>
  </div>;
}

export default OfferButtons;
