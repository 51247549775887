import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import colors from '../../../assets/variables.scss';
import Icon from "../../atoms/Icon";
import './style.scss';

export const Facebook = () => (
  <FacebookShareButton url={window.location.href} size={32} round={true} logoFillColor={colors['color-tuna']}>
    <Icon icon={'facebook'}/>
  </FacebookShareButton>);
export const Twitter = () => <TwitterShareButton url={window.location.href} size={32} round={true}
                                                 logoFillColor={colors['color-tuna']}>
  <Icon icon={'twitter'}/>
</TwitterShareButton>;
export const Mail = () => <EmailShareButton url={window.location.href} size={32} round={true}
                                            logoFillColor={colors['color-tuna']}>
  <Icon icon={'mail'}/>
</EmailShareButton>;

export default {
  Facebook,
  Twitter,
  Mail,
}

