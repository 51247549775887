import React from 'react';
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";

import ShowMoreCategoryOffers from "../../molecules/ShowMoreCategoryOffers";

import {generate} from "../../../routes";
import {useStores} from "../../../stores";

import './style.scss';

function ShowMoreCard({topic, history, className = '', children = null}) {
  const {criteriaStore} = useStores();

  function onClick() {
    criteriaStore.clear();
    criteriaStore.addTopics(topic.id);
    history.push(
      generate('search', criteriaStore.rawParams),
    );
  }

  return <div className={`OfferCard OfferCard--more ${className}`} onClick={onClick}>
    <div className="OfferCard-cover"/>
    <div className="OfferCard-title">
      <ShowMoreCategoryOffers id={topic.id}>
        <FormattedMessage
          id="organisms.offer_slider.more"
          defaultMessage="Show more"
        />
      </ShowMoreCategoryOffers>
    </div>
    {children}
  </div>
}

export default injectIntl(
  withRouter(
    observer(ShowMoreCard),
  ),
);
