export class SubmitOrderMutation {
  field_city = '';
  field_email = '';
  field_first_name = '';
  field_last_name = '';
  field_quantity = 0;
  field_school = '';
  field_street = '';
  field_title = '';
  field_offer = null;
  field_zip = '';
  field_address_number = '';
}

/**
 * @param {Object} values
 * @returns {SubmitOrderMutation}
 */
export function createSubmitOrderMutation(values = {}) {
  const mutation = new SubmitOrderMutation();
  const mutationKeys = Object.keys(mutation);

  Object.keys(values).forEach((key) => {
    const name = `field_${key}`;
    if (mutationKeys.includes(name)) {
      mutation[name] = values[key];
    }
  });

  return mutation;
}
