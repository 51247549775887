import {get} from "lodash";

import {sanitizeHtml} from "./strings";

const TITLE_SUFFIX = 'aws.ibw.at';

/**
 *
 * @param {Object} entry
 * @param {String} accessor
 * @returns {Function}
 */
export function createSetTitleCallback(entry, accessor) {
  return function updateHtmlMetadata() {
    let title = get(entry, accessor, '');
    title = sanitizeHtml(title, []);
    document.title = `${title} | ${TITLE_SUFFIX}`;
  }
}
