import qs from 'query-string';

export const routes = {
  home: '/',
  offer: '/offers/:id',
  search: '/search',
  static: '/static/:path'
};

/**
 *
 * @param {string} name
 * @param {Record<string, string>} params
 */
export function generate(name, params = {}) {
  if (!Object.keys(routes).includes(name)) {
    throw new Error(`Route called "${name}" is not registered in the map`);
  }

  let path = routes[name];

  Object.keys(params).forEach(key => {
    if (path.toString().includes(`:${key}`)) {
      path = path.replace(`:${key}`, params[key]);
      delete params[key];
    }
  });

  if (Object.keys(params).length === 0) {
    return `${path}`;
  }

  return `${path}?${stringifyQueryParams(params)}`;
}

export function generateStatic(path) {
  return generate('static', {path});
}

const QueryStringOptions = {
  arrayFormat: 'index'
};

/**
 * @param {String} url
 * @return {Object}
 */
export function parseQueryParams(url) {
  const {query} = qs.parseUrl(window.location.href, QueryStringOptions);

  return query;
}

/**
 * @param {Object} params
 * @returns {string}
 */
export function stringifyQueryParams(params) {
  return qs.stringify(params, QueryStringOptions);
}

export default  {
  routes,
  generate,
  generateStatic,
}
