import React from 'react';
import {debounce} from "lodash";

class EscapeHandler extends React.Component {
  static defaultProps = {
    onClick: () => {},
    clickWait: 500,
  };

  constructor(props, context) {
    super(props, context);
    this.handleEscape = debounce(
      this.handleEscape.bind(this),
      props.clickWait,
      {
        maxWait: props.clickWait,
        leading: true,
        trailing: false,
      }
    );
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.handleEscape);
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.handleEscape);
  }

  handleEscape(e) {
    e = e || window.event;
    let isEscape = false;
    if ("key" in e) {
      isEscape = (e.key === "Escape" || e.key === "Esc");
    } else {
      isEscape = (e.keyCode === 27);
    }

    if (isEscape) {
      this.props.onClick();
    }
  }

  render() {
    return <span/>
  }
}

export default EscapeHandler;
