import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {graphql} from 'react-apollo';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';
import {withRouter} from "react-router-dom";
import Sticky from 'react-stickynode';
import {FormattedMessage} from "react-intl";
import {isFunction, flowRight as compose} from "lodash";

import {useStores, withStores} from "../../../stores";

import {
  GetTagsAndYears,
  GetTopics,
  GetOffersLight,
  getOffersConditions,
  mapNodeCount,
  mapNodeEntities,
  mapNodeToMenu,
  mapTaxonomyCount,
} from "../../../api";
import {mapTopics} from "../../../stores/TopicsStore";
import Loader from "../../atoms/Loader";
import Transition from "../../molecules/Transition";
import EscapeHandler from "../../atoms/EscapeHandler";

import SearchIcon from "../../molecules/SearchIcon";
import SearchInput from "../../molecules/SearchInput";
import TopicList from "../../molecules/TopicList";
import TagList from "../../molecules/TagList";
import OfferList from "../../molecules/OfferList";
import YearsList from "../../molecules/YearsList";
import SearchSelectedCriteria, {
  TYPE_TOPIC,
  TYPE_TAG,
  TYPE_YEAR,
  TYPE_PAGE,
} from "../../molecules/SearchSelectedCriteria";
import {FILTER_TYPE_PAGE, FILTER_TYPE_TAG, FILTER_TYPE_TOPIC, FILTER_TYPE_YEAR} from "../../../stores/CriteriaStore";
import PredefinedFilters from "../PredefinedFilters";

import './style.scss';

const MAX_SEARCH_ENTRIES = 5;

const withSearchedTopics = graphql(GetTopics, {
  options: ({criteriaStore}) => {
    return {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        name: `%${criteriaStore.query}%`,
        limit: MAX_SEARCH_ENTRIES,
        offset: 0,
      },
    };
  },
  props: ({data}) => ({
    loading: data.loading,
    count: mapTaxonomyCount(data),
    entities: mapTopics({data}),
  }),
});

const withSearchedTags = graphql(GetTagsAndYears, {
  options: ({criteriaStore}) => {
    return {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        name: `%${criteriaStore.query}%`,
        limit: MAX_SEARCH_ENTRIES * 2,
        offset: 0,
      },
    };
  },
  props: ({data}) => ({
    loading: data.loading,
    count: mapTaxonomyCount(data),
    entities: mapTopics({data}),
  }),
});

const TITLE_PATTERN = 'PATTERN';

const withSearchedOffersByTitle = (pattern) => {
  return graphql(GetOffersLight, {
    options: ({criteriaStore, entities = []}) => ({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: getOffersConditions({
          title: pattern.toString().replace(TITLE_PATTERN, criteriaStore.query),
          excludedNodes: entities.map(offer => offer.id.toString()),
        }),
        limit: MAX_SEARCH_ENTRIES,
        offset: 0,
      },
    }),
    props: ({data, ownProps: {entities = [], count = 0}}) => ({
      loading: data.loading,
      entities: [...entities, ...mapNodeEntities(data, mapNodeToMenu)],
      count: mapNodeCount(data) + count,
    }),
  })
};

const SearchTopics = withStores(
  withSearchedTopics(
    observer(({entities, count, loading}) => {
      if (loading) {
        return <Loader/>;
      }

      if (!entities.length) {
        return null;
      }

      return <div className="col-sm-12 col-md-4">
        <h4 className={"Search-results-title"}>
          <FormattedMessage
            id="organisms.search.topics"
            defaultMessage="Topics ({count})"
            values={{count: count}}
          />
        </h4>
        <TopicList topics={entities}/>
      </div>;
    }),
  ),
);

const SearchTags = withStores(
  withSearchedTags(
    observer(({entities, count, loading}) => {
      if (loading) {
        return <Loader/>;
      }

      if (!entities.length && !count) {
        return null;
      }

      const tags = [...entities].filter(e => e.vid.targetId === 'tags');
      const years = [...entities].filter(e => e.vid.targetId === 'years');

      return <div className="col-sm-12 col-md-4">
        <h4 className={"Search-results-title"}>
          <FormattedMessage
            id="organisms.search.tags"
            defaultMessage="Tags ({count})"
            values={{count: count}}
          />
        </h4>
        <div className="Search-results-tags">
          <TagList tags={tags}/>
          <YearsList years={years}/>
        </div>
      </div>;
    }),
  ),
);

const SearchOffers = withStores(
  observer(({entities, count, loading}) => {
    if (loading) {
      return <Loader/>;
    }

    if (!entities.length) {
      return null;
    }

    return <div className="col-sm-12 col-md-4">
      <h4 className={"Search-results-title"}>
        <FormattedMessage
          id="organisms.search.offers"
          defaultMessage="Offers ({count})"
          values={{count: count}}
        />
      </h4>
      <OfferList offers={[...entities].slice(0, 5)}/>
    </div>;
  }),
);

const SearchOffersCombined = compose(
  withStores,
  withSearchedOffersByTitle(`${TITLE_PATTERN}%`),
  withSearchedOffersByTitle(`% ${TITLE_PATTERN} %`),
  withSearchedOffersByTitle(`%${TITLE_PATTERN}%`),
)(SearchOffers);

const SearchResult = () => {
  const {criteriaStore} = useStores();

  return <Transition in={!!criteriaStore.query.length} unmountOnExit>
    <div className="Search-results col-sm-12">
      <div className={"container"}>
        <div className={"row"}>
          <SearchTopics/>
          <SearchTags/>
          <SearchOffersCombined/>
        </div>
      </div>
    </div>
  </Transition>
};

const ChosenCriteria = withStores(
  observer(
    /**
     * @param criteriaStore
     * @param {TopicsStore} topicsStore
     * @param {TagsStore} tagsStore
     * @param {YearsStore} yearsStore
     * @param {PagesStore} pagesStore
     * @returns {*}
     * @constructor
     */
    function ChosenCriteria({criteriaStore, topicsStore, tagsStore, yearsStore, pagesStore}) {
      if (topicsStore.isLoading || tagsStore.isLoading || yearsStore.isLoading) {
        return [];
      }

      let criteria = [], key = 0;

      criteriaStore.filters.forEach((filter) => {
        let type, item;
        switch (filter.type) {
          case FILTER_TYPE_TOPIC:
            type = TYPE_TOPIC;
            item = topicsStore.get(filter.value);
            break;
          case FILTER_TYPE_TAG:
            type = TYPE_TAG;
            item = tagsStore.get(filter.value);
            break;
          case FILTER_TYPE_YEAR:
            type = TYPE_YEAR;
            item = yearsStore.get(filter.value);
            break;
          case FILTER_TYPE_PAGE:
            type = TYPE_PAGE;
            const page = pagesStore.get(filter.value);
            if (page) {
              item = {
                label: page.title,
                id: page.id,
              };
            }
            break;
          default:
            return false;
        }

        if (!item) {
          return;
        }

        criteria.push(
          <SearchSelectedCriteria key={key++} type={type} item={item}/>
        );
      });

      if (isMobile) {
        criteria = criteria.reverse();
      }

      return <div className="Search-tags">{criteria}</div>;
    }
  )
);

class Search extends React.Component {

  static defaultProps = {
    onEscape: () => {
    },
    onFocus: () => {
    },
    enablePredefinedFilters: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };

    this.elementRef = React.createRef();

    this.onEscapeHandler = this.onEscapeHandler.bind(this);
    this.setFocus = this.setFocus.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.onEscapeHandler();
      this.props.criteriaStore.setQuery('');
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  onEscapeHandler() {
    this.setState({
      focused: false,
    });
    this.props.onEscape();
  }

  setFocus(focused) {
    this.setState({focused});
  }

  onFocus() {
    this.setFocus(true);
    this.props.onFocus();
  }

  render() {
    const {criteriaStore} = this.props;

    return <div
      ref={this.elementRef}
      className={classNames(
        "Search",
        {"Search--active": this.state.focused},
      )}>
      <div className="container">
        <Transition in={!this.props.isSticky && this.props.enablePredefinedFilters} unmountOnExit>
          <PredefinedFilters/>
        </Transition>
        <div className="row">
          <div className="col Search-filters">
            <EscapeHandler onClick={this.onEscapeHandler}/>
            <SearchIcon/>
            <ChosenCriteria/>
            <SearchInput
              placeholder={this.props.placeholder}
              value={criteriaStore.query}
              onChange={value => {
                criteriaStore.setQuery(value);
                this.setFocus(!!value.length);
              }}
              onKeyDown={(e) => {
                let isEnter = false;
                if ("key" in e) {
                  isEnter = (e.key === "Enter");
                } else {
                  isEnter = (e.keyCode === 13);
                }
                if (isEnter) {
                  const button = this.elementRef.current.querySelector('.Search-results button');
                  if (button) button.click();
                }
              }}
              onClick={this.onFocus}
              onFocus={this.onFocus}
              className="Search-input"
            />
          </div>
        </div>
      </div>
      <div className="Search-container">
        <SearchResult/>
      </div>
    </div>
  }
}

function StickySearch(props) {
  const [isSticky, setSticky] = useState(false);
  const {criteriaStore} = useStores();

  return <Sticky innerZ={1000} top={"#navbar"}>
    {(status) => {

      if (status.status !== Sticky.STATUS_FIXED && isSticky && !criteriaStore.query.length) {
        setSticky(false);

        isFunction(props.onEscape) && props.onEscape();
      }

      if (status.status === Sticky.STATUS_FIXED) {
        setSticky(true);
        isFunction(props.onFocus) && props.onFocus();
      }

      return <Search {...props} isSticky={isSticky}/>
    }}
  </Sticky>
}

export default withRouter(
  withStores(
    observer(StickySearch),
  ),
);
