import React from "react";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {get} from "lodash";

import router from "../../../routes";
import BlurableButton from "../BlurableButton";
import List from "../List";
import {withStores} from "../../../stores";

const TagElement = withRouter(withStores(
  ({element, item, history, criteriaStore, withCounters}) => {
    const Element = element;
    const onClick = ({id}) => {
      return () => {
        criteriaStore.addTags(id);
        history.push(router.generate(
          'search',
          criteriaStore.rawParams,
        ));
      };
    };

    function renderCounter() {
      if (!withCounters) {
        return null;
      }

      return <>({get(item, 'tags.count', 0)})</>
    }

    return <li className="Nav-item">
      <Element onClick={onClick(item)}>
        {item.label}&nbsp;{renderCounter()}
      </Element>
    </li>;
  }
));

export const TagList = ({tags = [], withCounters = true, element = BlurableButton, className = ''}) => {
  return <List children={tags.map((item) => (
    <TagElement item={item} withCounters={withCounters} element={element}/>
  ))} className={className}/>
};

export default observer(TagList);
