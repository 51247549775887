import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './Transition.scss';

const DEFAULT_TRANSITION_TIMEOUT = 250;

export default (props) => {
  const overrides = Object.assign({}, props, {
    classNames: `Transition`,
    timeout: props.timeout || DEFAULT_TRANSITION_TIMEOUT,
  });
  return <CSSTransition {...overrides}/>;
};
