import {action, computed, decorate, observable} from 'mobx';
import {parseQueryParams, stringifyQueryParams} from "../routes";

export const FILTER_TYPE_TOPIC = 'topic';
export const FILTER_TYPE_TAG = 'tag';
export const FILTER_TYPE_YEAR = 'year';
export const FILTER_TYPE_PAGE = 'page';

class Filter {
  /**
   * @param {String}
   */
  type;

  /**
   * @param {String}
   */
  value;

  constructor(value, type) {
    this.type = type;
    this.value = value;
  }

  toUrl() {
    return `${this.type}:${this.value}`;
  }

  isTag() {
    return this.type === FILTER_TYPE_TAG;
  }

  isTopic() {
    return this.type === FILTER_TYPE_TOPIC;
  }

  isYear() {
    return this.type === FILTER_TYPE_YEAR;
  }

  isPage() {
    return this.type === FILTER_TYPE_PAGE;
  }

  equals(f) {
    return f.type === this.type && f.value === this.value;
  }

  static createTag(id) {
    return new Filter(`${id}`, FILTER_TYPE_TAG);
  }

  static createTopic(id) {
    return new Filter(`${id}`, FILTER_TYPE_TOPIC);
  }

  static createYear(id) {
    return new Filter(`${id}`, FILTER_TYPE_YEAR);
  }

  static createPage(id) {
    return new Filter(`${id}`, FILTER_TYPE_PAGE);
  }

  static fromUrl(param) {
    const [type, value] = param.split(':');
    return new Filter(value, type);
  }
}

class CriteriaQueryParams {
  static build(...filters) {
    return {
      filters: filters.map(filter => filter.toUrl()),
    };
  }
  static withTopics(...topics) {
    const ids = new Set(topics);
    const filters = [...ids].map(Filter.createTopic);
    return CriteriaQueryParams.build(...filters);
  }
}


class Store {
  filters = [];
  page = null;
  query = '';

  constructor() {
    this.fromQuery();
  }

  fromQuery() {
    let queryParams = parseQueryParams(window.location.href);

    queryParams = Object.assign({
      filters: [],
    }, queryParams);

    const filters = queryParams.filters.map((filter) => Filter.fromUrl(filter));

    this.setFilters(...filters);
    this.page = 0;
  }

  /**
   * @param {Filter[]} filters
   */
  addFilters(...filters) {
    filters
      .filter(filter => !this.contains(filter))
      .forEach(filter => this.filters.push(filter));
  }

  contains(filter) {
    for (const f of this.filters) {
      if (f.equals(filter)) {
        return true;
      }
    }

    return false;
  }

  addTags(...tags) {
    this.clearPages();
    tags
      .map(Filter.createTag)
      .filter(filter => !this.contains(filter))
      .forEach(filter => this.filters.push(filter))
    ;
  }

  removeTags(...tags) {
    tags.forEach(tag => {
      const filter = this.filters.find(filter => filter.isTag() && filter.value === `${tag}`);

      this.filters.remove(filter);
    });
  }

  setFilters(...filters) {
    this.filters.clear();
    this.page = 0;
    this.filters.push(...filters);
  }

  addTopics(...topics) {
    this.clearPages();
    topics
      .map(Filter.createTopic)
      .filter(filter => !this.contains(filter))
      .forEach(filter => this.filters.push(filter));
  }

  addPages(...pages) {
    this.clearPages();
    pages
      .map(Filter.createPage)
      .filter(filter => !this.contains(filter))
      .forEach(filter => this.filters.push(filter));
  }

  removeTopics(...topics) {
    topics.forEach(topic => {
      const index = this.filters.find(filter => filter.isTopic() && filter.value === `${topic}`);

      this.filters.remove(index);
    });
  }

  addYears(...years) {
    this.clearPages();
    years
      .map(Filter.createYear)
      .filter(filter => !this.contains(filter))
      .forEach(filter => this.filters.push(filter))
    ;
  }

  removeYears(...years) {
    years.forEach(year => {
      const index = this.filters.find(filter => filter.isYear() && filter.value === `${year}`);

      this.filters.remove(index);
    });
  }

  removePages(...pages) {
    pages.forEach(page => {
      const index = this.filters.find(filter => filter.isPage() && filter.value === `${page}`);

      this.filters.remove(index);
    });
  }

  setPage(page) {
    this.page = page;
  }

  setOffset(offset) {
    this.offset = parseInt(offset);
  }

  setQuery(query) {
    this.query = query.toString();
  }

  get queryParams() {
    const params = stringifyQueryParams(this.rawParams);
    return `?${params}`;
  }

  get rawParams() {
    return CriteriaQueryParams.build(...this.filters);
  }

  get topics() {
    return this.filters
      .filter(filter => filter.isTopic())
      .map(filter => filter.value);
  }

  get years() {
    return this.filters
      .filter(filter => filter.isYear())
      .map(filter => filter.value);
  }

  get tags() {
    return this.filters
      .filter(filter => filter.isTag())
      .map(filter => filter.value);
  }

  clear() {
    this.filters.clear();
    this.page = 0;
  }

  clearPages() {
    this.page = 0;
    this.filters
      .filter(filter => filter.isPage())
      .forEach(filter => this.filters.remove(filter));
  }
}

decorate(Store, {
  page: observable,
  query: observable,
  filters: observable,

  fromQuery: action,
  queryParams: computed,
  rawParams: computed,

  tags: computed,
  topics: computed,
  years: computed,

  addTags: action,
  addTopics: action,
  addYears: action,
  addPages: action,

  setPage: action,
  setQuery: action,
  setFilters: action,

  removeTags: action,
  removeTopics: action,
  removeYears: action,
  removePages: action,

  clear: action,
  clearPages: action,
});

export {
  Filter,
  CriteriaQueryParams,
};

export const CriteriaStore = new Store();
