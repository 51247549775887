import {observable, computed, action, decorate} from 'mobx';

import {drupalClient, GetYears, mapTaxonomyTermEntities, mapTaxonomyToMenu} from "../api";

export const mapTopics = ({data}) => {
  return mapTaxonomyTermEntities(data, mapTaxonomyToMenu);
};

class Years {
  isLoading = false;
  entries = new Map();

  constructor() {
    this.startLoading();
    drupalClient.query({
      query: GetYears,
      variables: {
        name: '%%', // @TODO review if there is better way to get all topics
      },
      fetchPolicy: 'cache-first',
    })
      .then(({data}) => {
        this.add(...mapTopics({data}));
      })
      .finally(() => this.stopLoading())
      .catch(console.error)
    ;
  }

  get(id) {
    return this.entries.get(id);
  }

  add(...years) {
    years.forEach(year => this.entries.set(year.id, year));
  }

  remove(...ids) {
    ids.forEach(id => this.entries.delete(id));
  }

  clear() {
    this.entries.clear();
  }

  startLoading() {
    this.isLoading = true;
  }

  stopLoading() {
    this.isLoading = false;
  }

  get all() {
    return [...this.entries.values()];
  }

   get ordered() {
    return [...this.entries.values()].sort((a, b) => a.weight - b.weight);
  }

  get ids() {
    return this.all.map(topic => topic.id);
  }
}

decorate(Years, {
  entries: observable,
  isLoading: observable,
  startLoading: action,
  stopLoading: action,
  add: action,
  remove: action,
  clear: action,
  all: computed,
  ordered: computed,
  ids: computed,
});

export const YearsStore = new Years();
