import React from "react";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {get} from "lodash";

import {useStores} from "../../../stores";
import router from "../../../routes";
import BlurableButton from "../BlurableButton";
import List from "../List";

const Topic = withRouter(({item, history, clear = false, withCounters = true}) => {
  const {criteriaStore} = useStores();
  const onClick = ({id}) => {
    return () => {
      if (clear) criteriaStore.clear();
      criteriaStore.addTopics(id);
      history.push(router.generate(
        'search',
        criteriaStore.rawParams,
      ));
    };
  };

  return <li className="Nav-item">
    <BlurableButton onClick={onClick(item)}>
      {item.label}
      {withCounters && (
        <span dangerouslySetInnerHTML={{__html: `&nbsp;(${get(item, 'topics.count', 0)})`}}/>
      )}
    </BlurableButton>
  </li>;
});

export const TopicList = ({topics = [], clear = false, withCounters = true}) => {
  return <List children={topics.map((item) => <Topic item={item} clear={clear} withCounters={withCounters}/>)}/>
};

export default observer(TopicList);
