import React from 'react';
import {graphql} from "react-apollo";
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";
import OwlCarousel from 'react-owl-carousel2';
import {debounce} from 'lodash';
import {isMobile} from "react-device-detect";

import {GetOffers, getOffersConditions, mapNodeEntities} from "../../../api";
import {withStores} from "../../../stores";

import Loader from "../../atoms/Loader";
import OfferCard from "../OfferCard";
import ShowMoreCard from "../ShowMoreCard";
import ShowMoreCategoryOffers from "../../molecules/ShowMoreCategoryOffers";

import scssVariables from '../../../assets/variables.scss';
import './style.scss';

export const MAX_OFFERS_TO_FETCH = 9;
export const MIN_OFFERS_TO_SHOW_EXTRA_CARD = 8;
export const DEFAULT_OFFERS_TO_SHOW = 4;

const SliderArrow = (
  {
    style = {},
    onClick = () => {
    },
    variant = 'left'
  }
) => {
  return (
    <div
      className={`OffersSlider-arrow OffersSlider-arrow--${variant}`}
      style={{...style}}
      onClick={onClick}
    />
  );
};

const waitTimeout = 100;
const debounceOptions = {
  maxWait: waitTimeout,
  leading: false,
  trailing: true,
};

function resolveResponsive() {
  const responsiveOptions = {};
  responsiveOptions[parseInt(scssVariables["xs"], 10)] = {items: 1};
  responsiveOptions[parseInt(scssVariables["sm"], 10)] = {items: 2};
  responsiveOptions[parseInt(scssVariables["md"], 10)] = {items: 2};
  responsiveOptions[parseInt(scssVariables["lg"], 10)] = {items: 3};
  responsiveOptions[parseInt(scssVariables["xl"], 10)] = {items: 4};

  return Object.assign({}, responsiveOptions);
}

class OffersSlider extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.elementRef = React.createRef();
    this.onNext = debounce(this.onNext.bind(this), waitTimeout, debounceOptions);
    this.onPrev = debounce(this.onPrev.bind(this), waitTimeout, debounceOptions);
  }

  onNext(event) {
    if (this.elementRef && this.elementRef.current) {
      this.elementRef.current.next();
    }
  }

  onPrev(event) {
    if (this.elementRef && this.elementRef.current) {
      this.elementRef.current.prev();
    }
  }

  renderOffers(offers, topic) {
    const result = [];

    offers.forEach((offer, key) => {
      result.push(<div className={"SearchResults-column"} key={key}>
        <OfferCard offer={offer}/>
      </div>);
    });

    if (offers.length > MIN_OFFERS_TO_SHOW_EXTRA_CARD) {
      result.pop();
      result.push(<div className={"SearchResults-column"} key={'show-more'}>
        <ShowMoreCard topic={topic}/>
      </div>);
    }

    return result;
  }

  render() {
    const {loading, offers, topic, options = {}, events = {}} = this.props;
    if (loading) {
      return <Loader/>
    }

    if (!offers.length) {
      return null;
    }

    const computedEvents = Object.assign({}, events);
    const computedOptions = Object.assign({}, {
      items: DEFAULT_OFFERS_TO_SHOW,
      responsive: resolveResponsive(),
      loop: false,
      nav: false,
      dots: false,
      info: true,
    }, options);
    return (
      <div className="OffersSlider">
        <h3 className="OffersSlider-header">
          <span className="OffersSlider-title">{topic.label}</span>
          <ShowMoreCategoryOffers id={topic.id}>
            <FormattedMessage
              id="organisms.offer_slider.more"
              defaultMessage="Show more"
            />
          </ShowMoreCategoryOffers>
        </h3>
        <div className="OffersSlider-container">
          <OwlCarousel
            className={'owl-carousel owl-theme '}
            options={computedOptions}
            events={computedEvents}
            ref={this.elementRef}
          >
            {this.renderOffers(offers, topic)}
          </OwlCarousel>
          { (offers.length > DEFAULT_OFFERS_TO_SHOW || isMobile) && (
            <div className="OffersSlider-nav">
              <SliderArrow onClick={this.onPrev}/>
              <SliderArrow variant="right" onClick={this.onNext}/>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class OffersSliderWrapper extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.slider = React.createRef();
    this.afterChangeHandler = debounce(
      this.afterChangeHandler.bind(this),
      waitTimeout,
      debounceOptions,
    );
  }

  afterChangeHandler() {
    if (!this.slider.current) {
      return;
    }

    const elements = [...this.slider.current.querySelectorAll('.owl-stage .owl-item')];
    const activeElements = [...this.slider.current.querySelectorAll('.owl-stage .active')];

    if (elements.length) {
      elements.forEach(element => element.classList.remove('last', 'first'));
    }

    if (activeElements.length) {
      activeElements.slice(0, 1).pop().classList.add('first');
      activeElements.slice(-1).pop().classList.add('last');
    }
  }

  render() {
    const props = Object.assign({}, this.props, {
      events: {
        onChanged: this.afterChangeHandler,
        onDragged: this.afterChangeHandler,
        onResized: this.afterChangeHandler,
        onInitialized: this.afterChangeHandler,
      }
    });
    return <div ref={this.slider}>
      <OffersSlider {...props} />
    </div>
  }
}

const withOffersQuery = graphql(GetOffers, {
  options: ({topic, limit}) => ({
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: getOffersConditions({topics: [topic.id]}),
      limit,
      offset: 0,
    },
  }),
  props: ({data: {error, loading, nodeQuery, fetchMore}, ownProps: {topic, limit}}) => ({
    loading,
    error,
    fetchMore,
    topic,
    limit,
    offers: mapNodeEntities({nodeQuery}, item => item),
    count: nodeQuery ? nodeQuery.count : 0,
  }),
});

export default observer(
  withOffersQuery(
    withStores(
      observer(OffersSliderWrapper),
    ),
  ),
);
