import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {observer} from "mobx-react";
import {isMobile} from "react-device-detect";
import {FormattedMessage, injectIntl} from "react-intl";

import {withStores} from "../../stores";
import Logo from '../atoms/Logo';
import Transition from '../molecules/Transition';
import Hamburger from "../atoms/Hamburger";
import BlurableButton from "../molecules/BlurableButton";
import Icon from "../atoms/Icon";
import {MainMenu} from "../../utils/staticPages";
import {Mail, Youtube, Facebook, Instagram} from '../molecules/SocialMedia'

import './Navbar.scss';

function Navbar({intl, location}) {
  const [isOpen, setOpen] = useState(!isMobile);

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [location.pathname, location.search]);

  return (
    <div className="container">
      <div className="row">
        <div className="col Navbar" id="navbar">
          <Logo className="Navbar-logo"/>
          {isMobile && (
            <BlurableButton onClick={() => setOpen(!isOpen)} className="Navbar-hamburger">
              <Hamburger
                active={isOpen}
                label={intl.formatMessage({
                  id: 'organisms.navbar.hamburger_label',
                  defaultMessage: 'Menu'
                })}
              />
            </BlurableButton>
          )}
          <Transition in={isOpen} unmountOnExit>
            <>
              <ul className="Navbar-menu">
                <li className="Navbar-menu-item">
                  <Link to={MainMenu.offer.url} target={MainMenu.offer.target}>
                    <Icon icon="offer"/>
                    <FormattedMessage
                      id={MainMenu.offer.id}
                      defaultMessage={MainMenu.offer.defaultMessage}
                    />
                  </Link>
                </li>
                <li className="Navbar-menu-item">
                  <Link to={MainMenu.partner.url} target={MainMenu.partner.target}>
                    <Icon icon="people"/>
                    <FormattedMessage
                      id={MainMenu.partner.id}
                      defaultMessage={MainMenu.partner.defaultMessage}
                    />
                  </Link>
                </li>
                <li className="Navbar-menu-item">
                  <Link to={MainMenu.about.url} target={MainMenu.about.target}>
                    <Icon icon="info"/>
                    <FormattedMessage
                      id={MainMenu.about.id}
                      defaultMessage={MainMenu.about.defaultMessage}
                    />
                  </Link>
                </li>
              </ul>
              <ul className="Navbar-menu Navbar-menu--shares">
                <li className="Navbar-menu-item">
                  <Mail/>
                </li>
                <li className="Navbar-menu-item">
                  <Facebook/>
                </li>
                <li className="Navbar-menu-item">
                  <Instagram/>
                </li>
                <li className="Navbar-menu-item">
                  <Youtube/>
                </li>
              </ul>
            </>
          </Transition>
        </div>
      </div>
    </div>
  )
}

export default withRouter(
  withStores(
    injectIntl(
      observer(Navbar),
    ),
  ),
);
