import {get} from "lodash";

export const mapTaxonomyTermEntities = (data, mapper) => get(data, 'taxonomyTermQuery.entities', []).map(mapper);

export const mapTaxonomyCount = (data) => get(data, 'taxonomyTermQuery.count', 0);

export const mapNodeEntities = (data, mapper) => get(data, 'nodeQuery.entities', []).map(mapper);

export const mapNodeCount = (data) => get(data, 'nodeQuery.count', 0);

export const mapNodeToMenu = (node) => Object.assign(node, {
  label: node.title,
  id: node.entityId,
});
export const mapTaxonomyToMenu = (node) => Object.assign(node, {
  label: node.entityLabel,
  id: node.entityId,
});
