import React from 'react';

import Button from "../../atoms/Button";

export default class BlurableButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.elementRef = React.createRef();
  }

  render() {
    return (
      <Button
        {...this.props}
        onClick={() => {
          this.props.onClick();
          // this.elementRef.current.blur();
          // this.elementRef.current.querySelectorAll('button').forEach(e => e.blur());
        }}>
        {this.props.children}
      </Button>
      // <span ref={this.elementRef} {...this.props}>
      //   <Button
      //     {...this.props}
      //     onClick={() => {
      //       this.props.onClick();
      //       this.elementRef.current.blur();
      //       this.elementRef.current.querySelectorAll('button').forEach(e => e.blur());
      //     }}>
      //   {this.props.children}
      //   </Button>
      // </span>
    );
  }
}
