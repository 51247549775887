import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {TextField} from "@material-ui/core";

import theme from './theme';

function AWSTextField(props = {}) {
  return <TextField {...props}/>;
}

const defaultProps = {
  variant: 'standard',
  margin: 'dense',
  InputProps: { disableUnderline: true, },
};

export default function (props = {}) {
  const newProps = Object.assign({}, defaultProps, props);
  return <ThemeProvider theme={theme}>
    <AWSTextField {...newProps}/>
  </ThemeProvider>
};
