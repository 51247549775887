import React from 'react';
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {graphql} from "react-apollo";

import StaticPageOrganism from '../../organisms/StaticPage';
import {withStores} from "../../../stores";
import {GetStaticPage} from "../../../api";
import withTracker from "../../../utils/withTracker";

class StaticPage extends React.Component {

  componentDidMount() {
    this.props.criteriaStore.clear();
  }

  componentWillUnmount() {
    this.props.criteriaStore.clear();
  }

  componentDidUpdate() {
    this.props.criteriaStore.clear();
  }

  render() {
    return <StaticPageOrganism page={this.props.page} loading={this.props.loading} error={this.props.error}/>
  }
}
const withStaticPage = graphql(GetStaticPage, {
  options: ({match}) => ({
    variables: {
      path: match.url,
    }
  }),
  props: ({data: {error, loading, route}}) => ({
    loading,
    error,
    page: route ? route.entity : null,
  })
});

export default withRouter(
  withStaticPage(
    withStores(
      withTracker(
        observer(
          StaticPage,
        ),
      ),
    ),
  ),
);
