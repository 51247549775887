import React from 'react';
import {Link} from "react-router-dom";

import logo from './logo.svg';
import './style.scss';

export default ({className}) => {
  return <Link to="/" className={"Logo"}>
    <img src={logo} className={`${className} Logo`} alt="AWS logo"/>
  </Link>;
};
