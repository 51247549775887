import React, {useEffect} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {injectIntl} from "react-intl";

import SearchResults from "../organisms/SearchResults";
import {withStores} from "../../stores";
import Loader from "../atoms/Loader";
import {createSetTitleCallback} from "../../utils/metadata";
import withTracker from "../../utils/withTracker";

function Search({intl}) {
  useEffect(createSetTitleCallback({
    title: intl.formatMessage({id: 'page.search.title'})
  }, 'title'));

  return <SearchResults/>;
}

const withQueryParamsBinding = (PassedComponent) => {
  return function withSearchParams({...props}) {
    const {criteriaStore, history} = props;
    const location = Object.assign({}, history.location);

    if (location.search !== criteriaStore.queryParams) {
      criteriaStore.fromQuery();

      return <div>
        <Loader/>
        <Redirect to={Object.assign(
          location,
          {search: criteriaStore.queryParams}
        )}/>
      </div>;
    }

    return (<PassedComponent {...props}/>);
  };
};

export default withRouter(
  withStores(
    injectIntl(
      withTracker(
        withQueryParamsBinding(
          observer(Search)
        ),
      ),
    ),
  ),
);
