import React from 'react';
import {withRouter} from "react-router-dom";
import Sticky from 'react-stickynode';

import Navbar from '../../organisms/Navbar';
import Search from '../Search';
import HomeSearch from "../HomeSearch";

import './style.scss';

function Header({location}) {
  const isHome = location.pathname === '/';
  const isSearch = location.pathname === '/search';
  return <div className="Header">
    <Sticky innerZ={1001}>
      <Navbar/>
    </Sticky>
    { !isHome && (<Search enablePredefinedFilters={isSearch}/>) }
    { isHome && (<HomeSearch/>) }
  </div>;
}

export default withRouter(Header);
