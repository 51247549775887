import React from 'react';
import {FormattedMessage} from "react-intl";

import Image from "../../atoms/Image";

import './style.scss';

import youtube from './yt.svg';
import facebook from './fb.svg';
import instagram from './instagram.svg';
import youtubeColorized from './yt-c.svg';
import facebookColorized from './fb-c.svg';
import Icon from "../../atoms/Icon";
import {FACEBOOK, INSTAGRAM, YOUTUBE} from "../../../utils/staticPages";
import SubscriptionPopup from "../SubscriptionPopup";

export function SocialMediaList({grayedOut = true}) {
  return (<ul className="SocialMedia-list">
    <li className="SocialMedia-list-item">
      <a href={FACEBOOK} target="_blank" className={"SocialMedia-link"} rel="noopener noreferrer">
        <Image src={grayedOut ? facebook : facebookColorized} alt="Facebook"/>
        <Icon icon="facebook"/>
        <FormattedMessage
          className="SocialMedia-title"
          id="molecules.social_media.facebook"
          defaultMessage="facebook"
        />
      </a>
    </li>
    <li className="SocialMedia-list-item">
      <a href={INSTAGRAM} target="_blank" className={"SocialMedia-link"} rel="noopener noreferrer">
        <Image src={grayedOut ? instagram : instagram} alt="Instagram AWSibw"/>
        <Icon icon="instagram"/>
        <FormattedMessage
          className="SocialMedia-title"
          id="molecules.social_media.instagram"
          defaultMessage="instagram"
        />
      </a>
    </li>
    <li className="SocialMedia-list-item">
      <a href={YOUTUBE} target="_blank" className={"SocialMedia-link"} rel="noopener noreferrer">
        <Image src={grayedOut ? youtube : youtubeColorized} alt="Youtube"/>
        <FormattedMessage
          className="SocialMedia-title"
          id="molecules.social_media.youtube"
          defaultMessage="youtube"
        />
      </a>
    </li>
  </ul>);
}

function SocialMedia() {
  return <div className="SocialMedia">
    <div className="row">
      <div className="col-md-6 offset-md-3">
        <div className="SocialMedia-title">
          <FormattedMessage
            className="SocialMedia-title"
            id="molecules.social_media.title"
            defaultMessage="Social Media"
          />
        </div>
        <SocialMediaList/>
      </div>
    </div>
  </div>
}

export const Mail = () => (
  <SubscriptionPopup>
    <button className={"SocialMedia-link"}>
      <Icon icon="mail"/>
    </button>
  </SubscriptionPopup>
);

export const Facebook = () => (<a href={FACEBOOK} target="_blank" className={"SocialMedia-link"} rel="noopener noreferrer">
        <Icon icon="facebook"/>
      </a>);

export const Youtube = () => (<a href={YOUTUBE} target="_blank" className={"SocialMedia-link"} rel="noopener noreferrer">
        <Icon icon="youtube"/>
      </a>);

export const Instagram = () => (<a href={INSTAGRAM} target="_blank" className={"SocialMedia-link"} rel="noopener noreferrer">
        <Icon icon="instagram"/>
      </a>);

export default SocialMedia;
